import React from 'react';
import { Colors } from 'modules/theme';
import moment from 'moment';
import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import Logo from 'assets/media/images/logo.png';
import Check from 'assets/media/images/check.png';
import ErrorIco from 'assets/media/images/error.png';
import PropTypes from 'prop-types';

const styles = StyleSheet.create({
  table: {
    marginTop: 15,
    display: 'table',
    width: 'auto',
    borderStyle: 'solid',
    borderColor: Colors.grayChart,
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    margin: 'auto',
    flexDirection: 'row',
  },
  tableCol: {
    borderStyle: 'solid',
    borderColor: Colors.grayChart,
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCell: {
    margin: 'auto',
    marginTop: 5,
    marginBottom: 5,
    fontSize: 10,
    color: Colors.gray,
  },
  line: {
    width: '100%',
    height: 1,
    backgroundColor: Colors.grayChart,
    marginTop: 5,
    marginBottom: 5,
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 5,
    marginBottom: 5,
  },
  col: {
    display: 'flex',
    flexDirection: 'column',
  },
});

export const ReportRO = ({
  totalGaloonsChart,
  averageChart,
  wasteChart,
  datePickerFromConsumption,
  datePickerToConsumption,
  exhaustionChart,

  datePickerFromTds,
  datePickerToTds,
  datePickerFromWaste,
  datePickerToWaste,
  allAlerts,
  catridgeSelectedObjet,
  reverseOsmosisConsumption: { last_updated, total_flow },
  reverseOsmosisLastHourTds,
  reverseOsmosisCurrentState,
  totalConsumptionRo,
  carbonCapacity,
  location: {
    brand_name,
    account_name,
    identificator,
    contact_first_name,
    contact_last_name,
    addres_line,
    addres_line_1,
    zip_code,
    City,
    State,
    ReverseOsmosisFilterHead,
  },
  reverseOsmosisCurrentBypass,
  inletPressure,
  tankPressure,
  systemPressure,
}) => {
  const { /* device_status, */ tds /* psi_2 */ } = reverseOsmosisCurrentState;
  const { device_id, installed_date, last_membrane_change_date } = ReverseOsmosisFilterHead;

  let replacedWeeks;
  let filterWeeksDuration;
  if (catridgeSelectedObjet && catridgeSelectedObjet.last_replaced) {
    const {
      SpotsReverseOsmosisFilter: {
        Catridge: { warranty: warrantyYears },
      },
    } = catridgeSelectedObjet;
    filterWeeksDuration = 52 * warrantyYears;
    const lastReplacedMoment = moment(catridgeSelectedObjet.last_replaced);
    replacedWeeks = moment().diff(lastReplacedMoment, 'weeks');
  }

  return (
    <Document>
      <Page wrap size="A4" style={{ padding: 25 }}>
        <View>
          <View style={{ flexDirection: 'row' }}>
            <View style={{ width: '70%' }}>
              <Image src={Logo} style={{ width: 170, marginBottom: 15 }} />

              <Text style={{ fontSize: 10, color: Colors.gray }}>
                Installed date:
                {installed_date
                  ? moment(installed_date).format('MM/DD/YYYY')
                  : 'Installation not Complete'}
              </Text>
              <Text style={{ fontSize: 10, color: Colors.gray }}>
                Current TDS:
                {tds || 'N/A'}
              </Text>
            </View>

            <View style={{ width: '30%' }}>
              <Text style={{ fontSize: 10, color: Colors.gray }}>Brand / Account</Text>
              <Text style={{ fontSize: 10 }}>
                {brand_name} /{account_name}
              </Text>

              <Text style={{ fontSize: 10, color: Colors.gray }}>Location ID</Text>
              <Text style={{ fontSize: 10 }}>{identificator}</Text>

              <Text style={{ fontSize: 10, color: Colors.gray }}>Store Contact</Text>
              <Text style={{ fontSize: 10 }}>
                {contact_first_name} {contact_last_name}
              </Text>

              <Text style={{ fontSize: 10, color: Colors.gray }}>Location Address</Text>
              <Text style={{ fontSize: 10 }}>{addres_line}</Text>
              <Text style={{ fontSize: 10 }}>{addres_line_1}</Text>
              <Text style={{ fontSize: 10 }}>
                {City.name},{State.name},{zip_code}
              </Text>
            </View>
          </View>
          <View style={{ marginBottom: 15 }}>
            <Text style={{ fontSize: 15 }}>
              Device ID: {device_id || 'Installation not complete'}
            </Text>
            <Text style={{ fontSize: 17 }}>
              {ReverseOsmosisFilterHead.ReverseOsmosisFilterConfiguration.name}
              <Text style={{ color: Colors.blue }}>
                {`(${ReverseOsmosisFilterHead.chlorine_or_chloramine})`}
              </Text>
            </Text>
          </View>

          <View>
            {(ReverseOsmosisFilterHead.FlowMeterOfROFilters || []).map(
              ({ SpotsReverseOsmosisFilter: { Catridge } }) => (
                <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                  <Text style={{ fontSize: 13 }}>
                    Model:
                    {Catridge.name}
                  </Text>
                  <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                    <Image
                      src={Catridge.scale ? Check : ErrorIco}
                      style={{ width: 15, height: 15, marginRight: 5 }}
                    />
                    <Text style={{ fontSize: 13 }}>Scale</Text>
                  </View>
                  <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                    <Image
                      src={Catridge.contaminant ? Check : ErrorIco}
                      style={{ width: 15, height: 15, marginRight: 5 }}
                    />
                    <Text style={{ fontSize: 13 }}>Contaminants</Text>
                  </View>
                  <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                    <Image
                      src={Catridge.taste_odor ? Check : ErrorIco}
                      style={{ width: 15, height: 15, marginRight: 5 }}
                    />
                    <Text style={{ fontSize: 13 }}>Taste/Odor</Text>
                  </View>
                </View>
              ),
            )}
          </View>

          <View style={styles.line} />

          <View style={styles.row}>
            <Text style={{ textAlign: 'center' }}>Water Flow</Text>
          </View>

          {!device_id ? (
            <Text style={{ textAlign: 'center', color: Colors.gray, fontSize: 10 }}>
              Installation not complete
            </Text>
          ) : (
            <View style={styles.row}>
              <View>
                <Text style={{ fontSize: 10, color: Colors.gray, marginBottom: 10 }}>
                  Time period: {moment(datePickerFromConsumption).format('MM/DD/YYYY')} -
                  {moment(datePickerToConsumption).format('MM/DD/YYYY')}
                </Text>
                <Image src={totalGaloonsChart} style={{ width: 260, heigth: 'auto' }} />
                {last_updated && (
                  <Text style={{ fontSize: 10, color: Colors.gray, textAlign: 'center' }}>
                    Last Updated: {moment.unix(last_updated).format('h:mma MM/DD/YYYY')}
                  </Text>
                )}
              </View>
              <View
                style={{
                  flexDirection: 'col',
                  width: '50%',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <View>
                  <Text style={{ fontSize: 15, textAlign: 'center' }}>
                    Permeate Flow Since Membrane Change: (
                    {last_membrane_change_date
                      ? moment(last_membrane_change_date).format('MM/DD/YYYY')
                      : 'The membrane has not yet been replaced'}
                    )
                  </Text>
                </View>
                <View>
                  <Text style={{ fontSize: 15, color: Colors.blue }}>
                    {totalConsumptionRo.reverseOsmosisTotalInletConsumption === 0
                      ? ' N/A'
                      : ` ${Math.round(
                          totalConsumptionRo.reverseOsmosisTotalInletConsumption,
                        )} gal`}
                  </Text>
                </View>

                <View>
                  <Text style={{ fontSize: 15 }}>Permeate Flow Period selected: </Text>
                </View>
                <View>
                  <Text style={{ fontSize: 15, color: Colors.blue }}>
                    {total_flow === 0 ? ' N/A' : ` ${Math.round(total_flow)} gal`}
                    {}
                  </Text>
                </View>
              </View>
            </View>
          )}

          <View style={styles.line} />

          <View style={{ alignItems: 'center', marginTop: 5 }}>
            <Text>Filter Catridges</Text>
            {catridgeSelectedObjet && catridgeSelectedObjet.last_replaced ? (
              <>
                <View style={styles.row}>
                  <View style={{ ...styles.row, width: '50%' }}>
                    <View
                      style={{
                        ...styles.col,
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '50%',
                      }}
                    >
                      <Text style={{ color: Colors.gray, fontSize: 15 }}>Exhaustion</Text>
                      <Text style={{ fontSize: 15 }}>
                        {totalConsumptionRo.reverseOsmosisTotalInletConsumption} /{carbonCapacity}{' '}
                        gal.
                      </Text>
                      <Text style={{ fontSize: 10, color: Colors.gray }}>Last Replaced:</Text>
                      <Text style={{ fontSize: 10, color: Colors.gray }}>
                        {moment(catridgeSelectedObjet.last_replaced).format('MMMM Do yyyy')}
                      </Text>
                    </View>
                    <View
                      style={{
                        ...styles.col,
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '50%',
                      }}
                    >
                      <Image src={exhaustionChart} style={{ width: 95, heigth: 'auto' }} />
                    </View>
                  </View>

                  <View style={{ ...styles.row, width: '50%' }}>
                    <View
                      style={{
                        ...styles.col,
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                      }}
                    >
                      <Text style={{ color: Colors.gray, fontSize: 15 }}>Expiration</Text>
                      <Text style={{ fontSize: 15 }}>{replacedWeeks} weeks.</Text>
                      <Text style={{ fontSize: 15, color: Colors.yellow }}>
                        {filterWeeksDuration - replacedWeeks} weeks remaining
                      </Text>
                    </View>
                  </View>
                </View>

                <View
                  style={{
                    ...styles.row,
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '50%',
                  }}
                >
                  <Text style={{ fontSize: 10, color: Colors.gray }}>
                    Summary cartridges:{' '}
                    {Math.round(totalConsumptionRo.reverseOsmosisTotalInletConsumption)} /{' '}
                    {carbonCapacity} gal.
                  </Text>
                </View>
              </>
            ) : (
              <Text style={{ color: Colors.gray, fontSize: 10 }}>Installation not complete</Text>
            )}
          </View>

          <View style={styles.line} />

          <View style={styles.row} wrap={false}>
            <View
              style={{
                ...styles.col,
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <Text style={{ margin: 10 }}>Bypass Status</Text>
              {!device_id ? (
                <Text style={{ color: Colors.gray, fontSize: 10 }}>Installation not complete</Text>
              ) : (
                <>
                  {!reverseOsmosisCurrentBypass || reverseOsmosisCurrentBypass === 0 ? (
                    <Text style={{ color: Colors.green }}>Off</Text>
                  ) : (
                    <Text style={{ color: Colors.red }}>On</Text>
                  )}
                </>
              )}
            </View>
          </View>

          <View style={styles.line} />

          <View style={styles.row} wrap={false}>
            <View
              style={{
                ...styles.col,
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <Text>Average TDS</Text>
              {!device_id ? (
                <Text style={{ color: Colors.gray, fontSize: 10 }}>Installation not complete</Text>
              ) : (
                <>
                  <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                    <Text
                      style={{ fontSize: 9, color: Colors.gray, marginBottom: 5, marginRight: 2 }}
                    >
                      Past hour:
                    </Text>
                    <Text style={{ fontSize: 9, color: Colors.blue, marginBottom: 5 }}>
                      {reverseOsmosisLastHourTds || 'N/A'} ppm
                    </Text>
                  </View>

                  <Text style={{ fontSize: 10, color: Colors.gray }}>
                    Time period: {moment(datePickerFromTds).format('MM/DD/YYYY')} -
                    {moment(datePickerToTds).format('MM/DD/YYYY')}
                  </Text>

                  <Image src={averageChart} style={{ width: '100%', heigth: 'auto' }} />
                </>
              )}
            </View>
          </View>
          <View style={styles.line} />

          <View style={styles.row} wrap={false}>
            <View
              style={{
                ...styles.col,
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <Text>Waste water percentage</Text>
              {!device_id ? (
                <Text style={{ color: Colors.gray, fontSize: 10 }}>Installation not complete</Text>
              ) : (
                <>
                  <Text style={{ fontSize: 10, color: Colors.gray }}>
                    Time period: {moment(datePickerFromWaste).format('MM/DD/YYYY')} -
                    {moment(datePickerToWaste).format('MM/DD/YYYY')}
                  </Text>

                  <Image src={wasteChart} style={{ width: '100%', heigth: 'auto' }} />
                </>
              )}
            </View>
          </View>

          <View style={styles.line} />

          <View style={styles.row} wrap={false}>
            <View
              style={{
                ...styles.col,
                justifyContent: 'center',
                alignItems: 'center',
                width: '33.3%',
              }}
            >
              <Text>Inlet Pressure</Text>
              {!device_id ? (
                <Text style={{ color: Colors.gray, fontSize: 10 }}>Installation not complete</Text>
              ) : inletPressure ? (
                <Text>{Math.round(inletPressure)} psi</Text>
              ) : (
                <Text style={{ color: Colors.blue }}>N/A</Text>
              )}
            </View>

            <View
              style={{
                ...styles.col,
                justifyContent: 'center',
                alignItems: 'center',
                width: '33.3%',
              }}
            >
              <Text>Tank Pressure</Text>
              {!device_id ? (
                <Text style={{ color: Colors.gray, fontSize: 10 }}>Installation not complete</Text>
              ) : tankPressure ? (
                <Text>{Math.round(tankPressure)} psi</Text>
              ) : (
                <Text style={{ color: Colors.blue }}>N/A</Text>
              )}
            </View>
            <View
              style={{
                ...styles.col,
                justifyContent: 'center',
                alignItems: 'center',
                width: '33.3%',
              }}
            >
              <Text>System Pressure</Text>
              {!device_id ? (
                <Text style={{ color: Colors.gray, fontSize: 10 }}>Installation not complete</Text>
              ) : systemPressure ? (
                <Text>{Math.round(systemPressure)} psi</Text>
              ) : (
                <Text style={{ color: Colors.blue }}>N/A</Text>
              )}
            </View>
          </View>
          <View style={styles.line} />

          {/* <View style={styles.row} wrap={false}>
            <View
              style={{
                ...styles.col,
                justifyContent: 'center',
                alignItems: 'center',
                width: '50%',
              }}
            >
              <Text>Temperature</Text>
              {!device_id ? (
                <Text style={{ color: Colors.gray, fontSize: 10 }}>Installation not complete</Text>
              ) : tds_temp ? (
                <Text>{tds_temp} ºF</Text>
              ) : (
                <Text style={{ color: Colors.blue }}>N/A</Text>
              )}
            </View>

            <View
              style={{
                ...styles.col,
                justifyContent: 'center',
                alignItems: 'center',
                width: '50%',
              }}
            >
              <Text>Membrane Life</Text>
              <Text style={{ color: Colors.blue }}>N/A</Text>
            </View>
          </View> */}

          <View style={styles.line} />

          {allAlerts && allAlerts.length > 0 && (
            <View style={styles.table}>
              {/* TableHeader */}

              <View style={styles.tableRow}>
                <View style={[styles.tableCol, { width: '20%' }]}>
                  <Text style={styles.tableCell}>Status</Text>
                </View>
                <View style={[styles.tableCol, { width: '20%' }]}>
                  <Text style={styles.tableCell}>Title</Text>
                </View>
                <View style={[styles.tableCol, { width: '20%' }]}>
                  <Text style={styles.tableCell}>Type</Text>
                </View>
                <View style={[styles.tableCol, { width: '20%' }]}>
                  <Text style={styles.tableCell}>Email Notification</Text>
                </View>
                <View style={[styles.tableCol, { width: '20%' }]}>
                  <Text style={styles.tableCell}>Sms Notification</Text>
                </View>
              </View>
              {/* TableContent */}

              {(allAlerts || []).map(alert => (
                <View style={styles.tableRow} wrap={false}>
                  <View style={[styles.tableCol, { width: '20%' }]}>
                    <Text style={[styles.tableCell, { color: Colors.yellow }]}>{alert.status}</Text>
                  </View>
                  <View style={[styles.tableCol, { width: '20%' }]}>
                    <Text style={styles.tableCell}>{alert.title} </Text>
                  </View>
                  <View style={[styles.tableCol, { width: '20%' }]}>
                    <Text style={styles.tableCell}>{alert.type}</Text>
                  </View>
                  <View style={[styles.tableCol, { width: '20%' }]}>
                    <Text style={styles.tableCell}>
                      <Image
                        src={alert.allow_email_notifications ? Check : ErrorIco}
                        style={{ width: 15, height: 15, marginRight: 5 }}
                      />
                    </Text>
                  </View>
                  <View style={[styles.tableCol, { width: '20%' }]}>
                    <Text style={styles.tableCell}>
                      <Image
                        src={alert.allow_sms_notifications ? Check : ErrorIco}
                        style={{ width: 15, height: 15, marginRight: 5 }}
                      />
                    </Text>
                  </View>
                </View>
              ))}
            </View>
          )}
        </View>
      </Page>
    </Document>
  );
};

ReportRO.propTypes = {
  allAlerts: PropTypes.array,
  averageChart: PropTypes.string,
  carbonCapacity: PropTypes.number,
  catridgeSelectedObjet: PropTypes.object,
  totalConsumptionRo: PropTypes.object,
  datePickerFromConsumption: PropTypes.string,
  datePickerFromTds: PropTypes.string,
  datePickerFromWaste: PropTypes.string,
  datePickerToConsumption: PropTypes.string,
  datePickerToTds: PropTypes.string,
  datePickerToWaste: PropTypes.string,
  exhaustionChart: PropTypes.string,
  inletPressure: PropTypes.number,
  location: PropTypes.object,
  reverseOsmosisConsumption: PropTypes.object,
  reverseOsmosisCurrentBypass: PropTypes.number,
  reverseOsmosisCurrentState: PropTypes.object,
  reverseOsmosisLastHourTds: PropTypes.number,
  systemPressure: PropTypes.number,
  tankPressure: PropTypes.number,
  totalGaloonsChart: PropTypes.string,
  wasteChart: PropTypes.string,
};
