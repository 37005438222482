import React, { Component } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Colors } from 'modules/theme';
import H1 from 'components/H1';
import H2 from 'components/H2';
import H3 from 'components/H3';
import H4 from 'components/H4';
import H5 from 'components/H5';
import Col from 'components/Col';
import Row from 'components/Row';
import DatePickerComponent from 'components/Datepicker/DatePickerComponent';
import Modal from 'components/Modal';
import Textarea from 'components/Textarea';
import LineGray from 'components/Line';
import Loader from 'components/Loader';
import Input from 'components/InputText';
import ButtonText from 'components/ButtonText';
import Filter, { FilterGroup, FilterContainer } from 'components/Filter';
import Icon from 'components/Icon';
import ListEmpty from 'components/ListEmpty';
import BG from 'components/BG';
import domtoimage from 'dom-to-image';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { Tooltip as TooltipReact } from 'react-tooltip';

import {
  getReverseOsmosisCurrentState,
  getReverseOsmosisConsumption,
  getTotalConsumptionReverseOsmosis,
  changeMembranes,
  getReverseOsmosisTds,
  getReverseOsmosisWaste,
  reverseOsmosisSetInitialState,
  generateReverseOsmosisCsv,
  getCsvFiles,
  getTotalConsumptionRo,
  getTotalConsumptionCartridgeRo,
  replaceReverseOsmosisCartridges,
  getReverseOsmosisBypass,
  getReverseOsmosisCurrentBypass,
  getReverseOsmosisCurrentPressure,
  getReverseOsmosisCurrentTemperature,
} from 'actions/index';
import {
  BarChart,
  PieChart,
  LineChart,
  Pie,
  Bar,
  Line,
  Sector,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  ReferenceArea,
  Tooltip,
  ResponsiveContainer,
  ComposedChart,
} from 'recharts';
import VerticalLine from 'components/Line';
import PropTypes from 'prop-types';
import { ReportRO } from './ReportRO';
import { ModalDatePicker } from './ModalDatePicker';
import ModalCsvFiles from './ModalCsvFiles';

const Container = styled.div`
  margin: 0px;
`;

const GraphContainer = styled.div`
  width: 100%;
  position: relative;
  min-height: ${props => props.height}px;
  display: ${props => props.display ?? 'inherit'};
  justify-content: ${props => props.justifyContent ?? 'center'};
  align-items: ${props => props.alignItems ?? 'center'};
  flex-direction: ${props => props.flexDirection ?? 'row'};
`;
const startDate = moment()
  .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
  .add(-7, 'days')
  .toDate();

const endDate = moment().toDate();

const startDateTds = moment()
  .add(-1, 'days')
  .toDate();

class RO extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cartridgesChangeDate: '',
      membraneChangeDate: '',
      modalMembrane: false,
      modalCartridges: false,
      description: '',
      generatePDF: false,
      downloadPDF: false,
      catridgeSelectedId: null,
      catridgeSelectedObjet: null,
      showConfiguration: false,
      modalViewInstructions: false,
      modalDatePickerCsv: false,
      modalDatePickerConsumption: false,
      modalDatePickerWaste: false,
      modalDatePickerTds: false,
      carbonCapacity: 0,
      datePickerFromConsumption: startDate,
      datePickerToConsumption: endDate,
      datePickerFromCsv: startDate,
      datePickerToCsv: endDate,
      datePickerFromTds: startDateTds,
      datePickerToTds: endDate,
      datePickerFromWaste: startDate,
      datePickerToWaste: endDate,
      rangeConsumption: 'days',
      possibleDayRangesConsumption: ['days'],
      rangeWaste: 'days',
      possibleDayRangesWaste: ['days'],
      rangeTds: 'hours',
      possibleDayRangesTds: ['hours', 'days'],
      csvType: 1,
      csvTypeString: 'raw_data',
      modalDeviceId: false,
      new_device_id: '',
      datePickerFromReport: startDate,
      datePickerToReport: endDate,
      modalDatePickerReport: false,
      modalDatePickerBypass: false,
      modalBypass: false,
      datePickerFromBypass: startDate,
      datePickerToBypass: endDate,
    };
  }

  componentDidMount() {
    const {
      location: { ReverseOsmosisFilterHead },
      reverseOsmosisSetInitialState,
    } = this.props;
    reverseOsmosisSetInitialState();
    const { installed_date, last_membrane_change_date } = ReverseOsmosisFilterHead;

    if (moment(startDate) < moment(installed_date)) {
      this.setState(
        {
          datePickerFromConsumption: moment(last_membrane_change_date).toDate(),
          datePickerFromCsv: moment(installed_date).toDate(),
          datePickerFromTds: moment(installed_date).toDate(),
          datePickerFromWaste: moment(installed_date).toDate(),
        },
        () => this.getInitialData(),
      );
    } else {
      this.getInitialData();
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.ro_for_pfd && this.props.ro_for_pfd) {
      this.putDataInPdf();
    }
  }

  putDataInPdf = async () => {
    try {
      const {
        location: { id: location_id },
        fetchAllAlerts,
      } = this.props;
      await fetchAllAlerts({ location_id });

      let totalGaloonsChart = document.getElementById('totalGaloonsChart');
      let averageChart = document.getElementById('averageChart');
      let wasteChart = document.getElementById('wasteChart');
      let exhaustionChart = document.getElementById('exhaustionChart');

      totalGaloonsChart = totalGaloonsChart && (await domtoimage.toPng(totalGaloonsChart));
      averageChart = averageChart && (await domtoimage.toPng(averageChart));
      wasteChart = wasteChart && (await domtoimage.toPng(wasteChart));
      exhaustionChart = exhaustionChart && (await domtoimage.toPng(exhaustionChart));

      this.setState({
        totalGaloonsChart,
        averageChart,
        wasteChart,
        exhaustionChart,
        downloadPDF: true,
      });
    } catch (error) {
      this.setState({ generatePDF: false });
      console.log(error);
    }
  };

  getInitialData = () => {
    const {
      location: { ReverseOsmosisFilterHead },
      getReverseOsmosisCurrentState,
      getReverseOsmosisConsumption,
      getReverseOsmosisTds,
      getReverseOsmosisWaste,
      getTotalConsumptionRo,
      getTotalConsumptionCartridgeRo,
      getReverseOsmosisCurrentBypass,
      getReverseOsmosisCurrentPressure,
      getReverseOsmosisCurrentTemperature,
    } = this.props;

    const {
      id: reverse_osmosis_filter_head_id,
      FlowMeterOfROFilters,
      last_membrane_change_date,
      device_id,
    } = ReverseOsmosisFilterHead;

    if (device_id) {
      const {
        datePickerFromConsumption,
        datePickerToConsumption,
        datePickerFromTds,
        datePickerToTds,
        datePickerFromWaste,
        datePickerToWaste,
        rangeConsumption,
        rangeWaste,
        rangeTds,
      } = this.state;

      const catridgeSelectedObjet = FlowMeterOfROFilters[0];
      if (catridgeSelectedObjet) {
        const carbonCapacity = this.calculePreCarbonCapacity();

        this.setState({
          catridgeSelectedId: catridgeSelectedObjet.SpotsReverseOsmosisFilter.Catridge.id,
          catridgeSelectedObjet,
          carbonCapacity,
        });

        getReverseOsmosisCurrentPressure({
          device_id,
          catridge_id: catridgeSelectedObjet.SpotsReverseOsmosisFilter.Catridge.id,
          from: catridgeSelectedObjet.last_replaced,
        });

        getReverseOsmosisCurrentTemperature({
          device_id,
          from: catridgeSelectedObjet.last_replaced,
        });

        if (catridgeSelectedObjet.last_replaced) {
          getTotalConsumptionCartridgeRo({
            device_id,
            from: catridgeSelectedObjet.last_replaced,
            spot_id: catridgeSelectedObjet.spot_reverse_osmosis_filter_id,
            reverse_osmosis_filter_head_id,
          });
        }
      }

      getReverseOsmosisCurrentBypass(device_id);

      getTotalConsumptionRo({
        device_id,
        last_membrane_change_date,
        last_catridge_change_date: catridgeSelectedObjet.last_replaced,
      });

      getReverseOsmosisCurrentState(device_id);

      getReverseOsmosisConsumption({
        device_id,
        from: datePickerFromConsumption,
        to: datePickerToConsumption,
        range: rangeConsumption,
      });

      getReverseOsmosisTds({
        device_id,
        from: datePickerFromTds,
        to: datePickerToTds,
        range: rangeTds,
      });

      getReverseOsmosisWaste({
        device_id,
        from: datePickerFromWaste,
        to: datePickerToWaste,
        range: rangeWaste,
      });
    }
  };

  calculePreCarbonCapacity = () => {
    const {
      location: {
        ReverseOsmosisFilterHead: { chlorine_or_chloramine, FlowMeterOfROFilters },
      },
    } = this.props;

    const preCarbonFilters = FlowMeterOfROFilters.filter(
      ({ SpotsReverseOsmosisFilter }) => SpotsReverseOsmosisFilter.position === 'Pre-carbon filter',
    );

    const totalCapacity = preCarbonFilters.reduce((total, { SpotsReverseOsmosisFilter }) => {
      const { Catridge } = SpotsReverseOsmosisFilter;
      return total + this.calculeCatridgeCapacity(Catridge, chlorine_or_chloramine);
    }, 0);

    return totalCapacity;
  };

  calculeCatridgeCapacity = (catridge, chlorine_or_chloramine) => {
    const capacitySelected =
      chlorine_or_chloramine === 'Chlorine'
        ? catridge.chlorine_capacity
        : catridge.chloramine_capacity;

    return catridge.scale_control_capacity
      ? capacitySelected && catridge.scale_control_capacity > capacitySelected
        ? capacitySelected
        : catridge.scale_control_capacity
      : capacitySelected;
  };

  cartidgeOnChange = evt => {
    const {
      location: {
        ReverseOsmosisFilterHead: {
          id: reverse_osmosis_filter_head_id,
          FlowMeterOfROFilters,
          device_id,
        },
      },
      getTotalConsumptionCartridgeRo,
    } = this.props;

    const catridgeSelectedObjet = FlowMeterOfROFilters.find(
      wf => wf.id === parseInt(evt.target.value, 10),
    );

    const {
      last_replaced,
      /* SpotsReverseOsmosisFilter : {  Catridge }, */
    } = catridgeSelectedObjet;

    if (last_replaced) {
      getTotalConsumptionCartridgeRo({
        device_id,
        from: catridgeSelectedObjet.last_replaced,
        spot_id: catridgeSelectedObjet.spot_reverse_osmosis_filter_id,
        reverse_osmosis_filter_head_id,
      });
    }

    this.setState({
      catridgeSelectedId: evt.target.value,
      catridgeSelectedObjet,
    });
  };

  switchModal = name => {
    const isVisible = this.state[name];
    this.setState({ [name]: !isVisible });
  };

  handleChangeDeviceId = evt => {
    this.setState({ new_device_id: evt.target.value });
  };

  handleDate = (value, key) => {
    this.setState({ [key]: value });
  };

  replaceReverseOsmosisCartridgesFunc = () => {
    const {
      location: {
        ReverseOsmosisFilterHead: { id: reverse_osmosis_filter_head_id },
      },
      replaceReverseOsmosisCartridges,
    } = this.props;
    const { cartridgesChangeDate } = this.state;
    const cartridgesChangeDateUTC = moment(cartridgesChangeDate)
      .utc()
      .format('YYYY-MM-DD HH:mm:ss');

    replaceReverseOsmosisCartridges({
      reverse_osmosis_filter_head_id,
      new_date: cartridgesChangeDateUTC,
    });
  };

  possibleRanges = daysRange => {
    const possibleDayRanges = [];
    if (daysRange <= 1) possibleDayRanges.push('hours');
    if (daysRange >= 1 && daysRange < 15) possibleDayRanges.push('days');
    if (daysRange >= 14 && daysRange < 105) possibleDayRanges.push('weeks');
    if (daysRange >= 30 && daysRange < 450) possibleDayRanges.push('months');
    if (daysRange >= 365) possibleDayRanges.push('years');

    return possibleDayRanges;
  };

  generateReverseOsmosisCsv = () => {
    const {
      generateReverseOsmosisCsv,
      location: {
        id,
        ReverseOsmosisFilterHead: { device_id },
      },
    } = this.props;
    const { csvTypeString } = this.state;

    const { datePickerFromCsv: from, datePickerToCsv: to } = this.state;
    generateReverseOsmosisCsv({ device_id, location_id: id, from, to, csvContains: csvTypeString });
  };

  changeCsvType = (csvType, nro) => {
    const csvTypes = ['raw_data', 'calculated_data', 'all_data'];

    this.setState({ [csvType]: nro, csvTypeString: csvTypes[nro - 1] });
  };

  calculeReverseOsmosisConsumption = (range, ro_for_pfd) => {
    const {
      getReverseOsmosisConsumption,
      location: {
        ReverseOsmosisFilterHead: { device_id },
      },
    } = this.props;
    const { datePickerFromConsumption: from, datePickerToConsumption: to } = this.state;
    const fromDate = moment(from);
    const toDate = moment(to);
    const daysRange = toDate.diff(fromDate, 'days');
    const possibleDayRanges = this.possibleRanges(daysRange);

    const currentRange = range || possibleDayRanges[0];
    getReverseOsmosisConsumption({ device_id, from, to, range: currentRange, ro_for_pfd });
    this.setState({
      possibleDayRangesConsumption: possibleDayRanges,
      rangeConsumption: currentRange,
    });
  };

  calculeReverseOsmosisTds = (range, ro_for_pfd) => {
    const {
      getReverseOsmosisTds,
      location: {
        ReverseOsmosisFilterHead: { device_id },
      },
    } = this.props;
    const { datePickerFromTds: from, datePickerToTds: to } = this.state;
    const fromDate = moment(from);
    const toDate = moment(to);
    const daysRange = toDate.diff(fromDate, 'days');
    const possibleDayRanges = this.possibleRanges(daysRange);
    const currentRange = range || possibleDayRanges[0];
    getReverseOsmosisTds({ device_id, from, to, range: currentRange, ro_for_pfd });
    this.setState({
      possibleDayRangesTds: possibleDayRanges,
      rangeTds: currentRange,
    });
  };

  calculeReverseOsmosisWaste = (range, ro_for_pfd) => {
    const {
      getReverseOsmosisWaste,
      location: {
        ReverseOsmosisFilterHead: { device_id },
      },
    } = this.props;
    const { datePickerFromWaste: from, datePickerToWaste: to } = this.state;
    const fromDate = moment(from);
    const toDate = moment(to);
    const daysRange = toDate.diff(fromDate, 'days');
    const possibleDayRanges = this.possibleRanges(daysRange);

    const currentRange = range || possibleDayRanges[0];
    getReverseOsmosisWaste({ device_id, from, to, range: currentRange, ro_for_pfd });
    this.setState({
      possibleDayRangesWaste: possibleDayRanges,
      rangeWaste: currentRange,
    });
  };

  generateReport = async () => {
    try {
      const { datePickerFromReport, datePickerToReport } = this.state;
      this.setState(
        {
          datePickerFromConsumption: datePickerFromReport,
          datePickerToConsumption: datePickerToReport,
          datePickerFromTds: datePickerFromReport,
          datePickerToTds: datePickerToReport,
          datePickerFromWaste: datePickerFromReport,
          datePickerToWaste: datePickerToReport,
          generatePDF: true,
        },
        () => {
          this.calculeReverseOsmosisConsumption(false, true);
          this.calculeReverseOsmosisWaste(false, true);
          this.calculeReverseOsmosisTds(false, true);
        },
      );
    } catch (error) {
      console.log(error);
      this.setState({ generatePDF: false });
    }
  };

  calculeWaterFilterBypass = range => {
    const {
      getReverseOsmosisBypass,
      location: {
        ReverseOsmosisFilterHead: { device_id, installed_date },
      },
    } = this.props;
    const { datePickerFromBypass: from, datePickerToBypass: to } = this.state;

    const possibleDayRangesBypass = this.calculeDaysRange(from, to);

    const currentRange = range || possibleDayRangesBypass[0];
    getReverseOsmosisBypass({ device_id, from, to, installed_date, range: currentRange });
    this.setState({ possibleDayRangesBypass, range: currentRange });
  };

  calculeDaysRange = (from, to) => {
    const possibleDayRanges = [];
    const fromDate = moment(from);
    const toDate = moment(to);
    const daysRange = toDate.diff(fromDate, 'days');

    // The maximum of days chosen in each condition is the equivalent of the maximum of 15 bars on the chart
    if (daysRange <= 1) possibleDayRanges.push('hours');
    if (daysRange >= 1 && daysRange < 15) possibleDayRanges.push('days');
    if (daysRange >= 14 && daysRange < 105) possibleDayRanges.push('weeks');
    if (daysRange >= 30 && daysRange < 450) possibleDayRanges.push('months');
    if (daysRange >= 365) possibleDayRanges.push('years');
    return possibleDayRanges;
  };

  renderBypassFrequency = () => {
    const {
      reverseOsmosisCurrentBypassLoading,
      reverseOsmosisCurrentBypass,
      location: {
        ReverseOsmosisFilterHead: { device_id },
      },
    } = this.props;

    return (
      <Col lg={50} md={50} sm={100} xs={100}>
        <H2>Bypass Status</H2>
        {reverseOsmosisCurrentBypassLoading ? (
          <Loader size={50} block />
        ) : !device_id ? (
          <Col justifyContent="center">
            <ListEmpty message="Installation Not Complete" />
          </Col>
        ) : (
          <>
            <Row justifyContent="center">
              {!reverseOsmosisCurrentBypass || reverseOsmosisCurrentBypass === 0 ? (
                <H1 color={Colors.green}>Off</H1>
              ) : (
                <H1 color={Colors.red}>On</H1>
              )}
            </Row>
            <H3
              lineHeight="0"
              color={Colors.lightBlue}
              textAlign="center"
              style={{ cursor: 'pointer' }}
              onClick={() => {
                this.switchModal('modalBypass');
                this.calculeWaterFilterBypass();
              }}
            >
              Open More
            </H3>
          </>
        )}
      </Col>
    );
  };

  renderBypassConsumptions = () => {
    const {
      reverseOsmosisBypass: { consumption, total_flow, last_updated },
      reverseOsmosisBypassLoading,
      location: {
        ReverseOsmosisFilterHead: { device_id },
      },
    } = this.props;

    const { possibleDayRangesBypass, datePickerFromBypass, datePickerToBypass } = this.state;

    const CustomTooltip = ({ payload, active }) => {
      if (active && payload && payload[0]) {
        const currentPayload = payload[0].payload;
        return (
          <div
            style={{
              backgroundColor: 'white',
              padding: 1,
              borderRadius: 5,
              borderColor: 'gray',
              borderStyle: 'solid',
              borderWidth: 1,
            }}
          >
            <p style={{ color: 'gray' }}>{currentPayload.tooltipLabel}</p>
            <p style={{ color: 'gray' }}>Flow:{Math.round(currentPayload.gal)} Gal</p>
          </div>
        );
      }

      return null;
    };

    return (
      <>
        {reverseOsmosisBypassLoading ? (
          <GraphContainer height={450}>
            <Loader size={200} block />
          </GraphContainer>
        ) : (
          <GraphContainer height={450}>
            <>
              <Col style={{ height: 300 }} justifyContent="center">
                <Row style={{ padding: '2%' }}>
                  <Col lg={40} md={40} sm={40} xs={40}>
                    <Row
                      justifyContent="flex-start"
                      alignItems="center"
                      data
                      style={{ cursor: 'pointer' }}
                    >
                      {possibleDayRangesBypass?.map(range => (
                        <H5
                          cursor="pointer"
                          color={range === this.state.range ? Colors.lightBlue : Colors.gray}
                          style={{ marginLeft: 5, marginRight: 5 }}
                          onClick={() => this.calculeWaterFilterBypass(range)}
                        >
                          {range}
                        </H5>
                      ))}
                    </Row>
                  </Col>
                  <Col lg={60} md={60} sm={60} xs={60}>
                    <Row
                      justifyContent="flex-end"
                      alignItems="center"
                      data
                      style={{ cursor: 'pointer' }}
                      onClick={() => this.switchModal('modalDatePickerBypass')}
                    >
                      <H5 color={Colors.lightBlue} style={{ marginLeft: 5, marginRight: 5 }}>
                        {moment(datePickerFromBypass).format('MM/DD/YYYY')} -
                        {moment(datePickerToBypass).format('MM/DD/YYYY')}
                      </H5>
                      <Icon
                        name="calendar-alt"
                        color={Colors.lightBlue}
                        customStyle={{ fontSize: '20px' }}
                      />
                    </Row>
                  </Col>
                </Row>
                <ResponsiveContainer width="100%">
                  {!device_id ? (
                    <Col style={{ height: 300 }} justifyContent="center">
                      <ListEmpty message="Installation Not Complete" />
                    </Col>
                  ) : (
                    <ComposedChart
                      data={consumption}
                      margin={{ right: 20, left: -15, bottom: 0 }}
                      barSize={20}
                    >
                      <XAxis
                        dataKey="name"
                        stroke={Colors.gray}
                        tick={{ fontSize: 12 }}
                        scale="point"
                        padding={{ left: 15, top: 15 }}
                      />

                      <YAxis type="number" unit="" stroke={Colors.gray} tick={{ fontSize: 12 }} />
                      <Tooltip content={<CustomTooltip />} />

                      <CartesianGrid strokeDasharray="3 3" />

                      <Bar dataKey="gal">
                        {consumption.map((entry, index) => (
                          <Cell
                            key={`cell-${index}`}
                            fill={Colors.lightBlue}
                            background={{ fill: 'transparent' }}
                          />
                        ))}
                      </Bar>
                    </ComposedChart>
                  )}
                </ResponsiveContainer>
              </Col>
              {last_updated && (
                <H5 color={Colors.gray8}>
                  Last updated: {moment.unix(last_updated).format('h:mma MM/DD/YYYY')}
                </H5>
              )}
            </>
            <VerticalLine style={{ marginTop: 15, marginBottom: 15 }} />
            <Row>
              <Col xs={100} md={100} sm={40} lg={40}>
                <H4
                  textAlign="center"
                  weight="bold"
                  marginTop={5}
                  style={{ letterSpacing: 1, lineHeight: 1 }}
                >
                  Total Flow
                </H4>
                <H5 weight="bold" color={Colors.lightBlue} marginTop={5}>
                  since installation
                </H5>
                <H2 color={Colors.blue}>{total_flow === 0 ? 'N/A' : Math.round(total_flow)}</H2>
                {total_flow !== 0 && <H2>gal</H2>}
              </Col>
            </Row>
          </GraphContainer>
        )}
      </>
    );
  };

  renderConfigurationModal = () => {
    const { showConfiguration } = this.state;
    const {
      location: {
        ReverseOsmosisFilterHead: {
          ReverseOsmosisFilterConfiguration,
          chlorine_or_chloramine,
          FlowMeterOfROFilters,
        },
      },
    } = this.props;
    const { name } = ReverseOsmosisFilterConfiguration;

    return (
      <Modal
        title="Configuration"
        onClose={() => this.switchModal('showConfiguration')}
        isVisible={showConfiguration}
      >
        <H4 lineHeight={2} textAlign="left" color={Colors.gray8} weight={325} style={{ margin: 0 }}>
          Configuration name
        </H4>
        <H4 lineHeight={2} color={Colors.black} weight={500} style={{ marginTop: 5 }}>
          {name}
        </H4>
        <H4 lineHeight={2} color={Colors.black} weight={500} style={{ marginTop: 5 }}>
          <Icon name="check-circle" color={Colors.lightBlue} customStyle={{ marginRight: 5 }} />
          {chlorine_or_chloramine}
        </H4>

        {FlowMeterOfROFilters.map(({ SpotsReverseOsmosisFilter, type, name }) => {
          const { ReverseOsmosisFilter, Catridge } = SpotsReverseOsmosisFilter;
          return (
            <>
              <H4 lineHeight={2} color={Colors.lightBlue} weight="bold" style={{ marginTop: 5 }}>
                {ReverseOsmosisFilter.name}
              </H4>

              <Row alignItems="left" lg={100} md={100} sm={100} xs={100}>
                <Col lg={30} md={30} sm={30} xs={30} alignItems="left">
                  <H4 lineHeight={2} weight="bold" style={{ marginTop: 5 }}>
                    {SpotsReverseOsmosisFilter.position} -{Catridge.name}
                  </H4>
                </Col>
                <Col lg={70} md={70} sm={70} xs={70} alignItems="left">
                  <H4 lineHeight={2} weight="bold" style={{ marginTop: 5 }}>
                    Capacity: {this.calculeCatridgeCapacity(Catridge, chlorine_or_chloramine)} gal
                  </H4>
                </Col>
                <>
                  <Col lg={30} md={30} sm={30} xs={30} alignItems="left">
                    <H4
                      lineHeight={2}
                      textAlign="left"
                      color={Colors.gray8}
                      weight={325}
                      style={{ margin: 0 }}
                    >
                      Type
                    </H4>

                    <H4 lineHeight={2} weight="bold" style={{ marginTop: 5 }}>
                      {type}
                    </H4>
                  </Col>
                  <Col lg={70} md={70} sm={70} xs={70} alignItems="left">
                    <H4
                      lineHeight={2}
                      textAlign="left"
                      color={Colors.gray8}
                      weight={325}
                      style={{ margin: 0 }}
                    >
                      Name
                    </H4>
                    <H4 lineHeight={2} weight="bold" style={{ marginTop: 5 }}>
                      {name}
                    </H4>
                  </Col>

                  {Catridge.photo && (
                    <Col alignItems="start" xs={100} md={100} sm={100} lg={100}>
                      <img width={200} src={Catridge.photo} alt="catridge_photo" />
                    </Col>
                  )}
                  <LineGray />
                </>
              </Row>
            </>
          );
        })}
      </Modal>
    );
  };

  renderActiveShape = props => {
    const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill, payload } = props;
    return (
      <g>
        <text
          x={cx}
          y={cy}
          dy={8}
          textAnchor="middle"
          fontFamily="Gotham, Sans Serif"
          fontSize={23}
          fill={Colors.black}
        >
          {100 - payload.value}%
        </text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
      </g>
    );
  };

  renderPressure = () => {
    const iconTime = <Icon name="weight" color={Colors.yellow} fontSize={22} />;
    if (this.props.data.length !== 0) {
      return (
        <Col lg={50} md={50} sm={100} xs={100}>
          <H2 marginBottom={10} textAlign="center">
            Pressure Differential
          </H2>

          <Row>
            <Col lg={50} md={50} sm={50} xs={50}>
              <H4>A 145689</H4>
            </Col>
            <Col lg={50} md={50} sm={50} xs={50}>
              <Row justifyContent="center">
                {iconTime}
                <H4 color={Colors.blue} marginLeft={5} marginRight={5}>
                  45
                </H4>
                <H4>PSI</H4>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col lg={50} md={50} sm={50} xs={50}>
              <H4>A 145689</H4>
            </Col>
            <Col lg={50} md={50} sm={50} xs={50}>
              <Row justifyContent="center">
                {iconTime}
                <H4 color={Colors.blue} marginLeft={5} marginRight={5}>
                  45
                </H4>
                <H4>PSI</H4>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col lg={50} md={50} sm={50} xs={50}>
              <H4>A 145689</H4>
            </Col>
            <Col lg={50} md={50} sm={50} xs={50}>
              <Row justifyContent="center">
                {iconTime}
                <H4 color={Colors.blue} marginLeft={5} marginRight={5}>
                  45
                </H4>
                <H4>PSI</H4>
              </Row>
            </Col>
          </Row>
        </Col>
      );
    }
    return (
      <Col lg={50} md={50} sm={100} xs={100}>
        <H2 lineHeight="1" textAlign="center" style={{ margin: 10 }}>
          Pressure
        </H2>
        <H1 lineHeight="0" color={Colors.blue}>
          N/A
        </H1>
      </Col>
    );
  };

  renderReverseOsmosisConsumptions = () => {
    const {
      reverseOsmosisConsumption: { consumption, last_updated, total_flow },
      reverseOsmosisConsumptionLoading,
      location: {
        ReverseOsmosisFilterHead: { device_id, last_membrane_change_date },
      },
      totalConsumptionRoLoading,
      totalConsumptionRo,
    } = this.props;

    const {
      datePickerFromConsumption,
      datePickerToConsumption,
      modalDatePickerConsumption,
    } = this.state;

    const totalInletFlow = consumption.reduce((acc, curr) => acc + (curr.galInlet || 0), 0);

    const CustomTooltip = ({ payload, active }) => {
      if (active && payload && payload[0]) {
        const currentPayload = payload[0].payload;
        return (
          <div
            style={{
              backgroundColor: 'white',
              padding: 1,
              borderRadius: 5,
              borderColor: 'gray',
              borderStyle: 'solid',
              borderWidth: 1,
            }}
          >
            <p style={{ color: 'gray' }}>{currentPayload.tooltipLabel}</p>
            <p style={{ color: 'gray' }}>{Math.round(currentPayload.gal)} Gal Permeate</p>
            <p style={{ color: 'gray' }}>{Math.round(currentPayload.galInlet)} Gal Inlet</p>

            {/* MARK: GAL INLET */}
          </div>
        );
      }

      return null;
    };

    return (
      <>
        {reverseOsmosisConsumptionLoading ? (
          <GraphContainer height={420}>
            <Loader size={140} block />
          </GraphContainer>
        ) : (
          <GraphContainer
            height={425}
            style={{ minHeight: 60, width: '100%', marginLeft: 0, justifyContent: 'flex-start' }}
          >
            <Col
              style={{
                display: 'flex',
                width: '100%',
                marginLeft: 0,
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}
            >
              <Row id="permeateFlowCol" style={{ display: 'flex', justifyContent: 'flex-start' }}>
                <H4
                  textAlign="left"
                  weight="bold"
                  marginTop={5}
                  style={{ letterSpacing: 1, lineHeight: 1 }}
                >
                  Flow Volume
                </H4>

                <div>
                  <a
                    data-tooltip-id="my-tooltip"
                    data-tooltip-html="<strong>Reverse Osmosis Flow Volume Graph</strong> <br><strong>Inlet Flow:</strong> Total flow volume of water entering the RO system. <br><strong>Permeate Flow:</strong> Total flow volume of clean water exiting the RO system."
                  >
                    <Icon
                      name="info-circle"
                      customStyle={{ marginLeft: 5, marginRight: 5, paddingLeft: 5 }}
                    />
                  </a>
                  <TooltipReact id="my-tooltip" style={{ zIndex: 9999 }} />
                </div>

                <Col lg={50} md={50} sm={50} xs={50}>
                  <Row
                    justifyContent="flex-end"
                    alignItems="center"
                    data
                    style={{ cursor: 'pointer' }}
                    onClick={() => this.switchModal('modalDatePickerConsumption')}
                  >
                    <H5 color={Colors.lightBlue} style={{ margin: 7 }}>
                      {moment(datePickerFromConsumption).format('MM/DD/YYYY')}-
                      {moment(datePickerToConsumption).format('MM/DD/YYYY')}
                    </H5>
                    <Icon
                      name="calendar-alt"
                      color={Colors.lightBlue}
                      customStyle={{ fontSize: '20px' }}
                    />
                  </Row>
                </Col>

                <ModalDatePicker
                  minDate={moment(last_membrane_change_date).toDate()}
                  isVisible={modalDatePickerConsumption}
                  handleDateFrom={value => this.handleDate(value, 'datePickerFromConsumption')}
                  handleDateTo={value => this.handleDate(value, 'datePickerToConsumption')}
                  switchModal={() => this.switchModal('modalDatePickerConsumption')}
                  dateFrom={datePickerFromConsumption}
                  dateTo={datePickerToConsumption}
                  saveChanges={() => {
                    this.switchModal('modalDatePickerConsumption');
                    this.calculeReverseOsmosisConsumption();
                  }}
                />
              </Row>
            </Col>

            <hr />

            {/* Graph Legend */}
            <Row
              style={{ minHeight: 60, width: '100%', marginLeft: 0, justifyContent: 'flex-start' }}
              id="valuesRow"
            >
              {/* inlet flow */}
              <Col lg={33} md={33} sm={33} xs={33}>
                <Row
                  alignItems="left"
                  style={{
                    whiteSpace: 'nowrap',
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                  }}
                >
                  <Icon
                    name="square"
                    color={Colors.lightBlue}
                    customStyle={{
                      fontSize: '20px',
                      borderRadius: '4px',
                      marginRight: '4px',
                    }}
                  />
                  {/* MARK: inlet flow */}
                  <H5
                    color={Colors.gray}
                    style={{ display: 'inline', marginLeft: '5px', margin: 0 }}
                  >
                    Inlet Flow &nbsp;
                  </H5>
                  <H5
                    color={Colors.blue}
                    style={{ display: 'inline', marginLeft: '5px', margin: 0 }}
                  >
                    {' '}
                    {total_flow === 0 ? 'N/A' : Math.round(totalInletFlow)} gal
                  </H5>
                </Row>
                <Row
                  alignItems="left"
                  style={{
                    whiteSpace: 'nowrap',
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                  }}
                />
              </Col>
              <Col lg={33} md={33} sm={33} xs={33}>
                <Row
                  alignItems="left"
                  style={{
                    whiteSpace: 'nowrap',
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                  }}
                >
                  <Icon
                    name="square"
                    color="#97D977"
                    customStyle={{
                      fontSize: '20px',
                      borderRadius: '4px',
                      marginRight: '4px',
                    }}
                  />
                  <H5
                    color={Colors.gray}
                    style={{ display: 'inline', marginLeft: '5px', margin: 0 }}
                  >
                    Permeate Flow&nbsp;
                  </H5>
                  <H5
                    color={Colors.blue}
                    style={{ display: 'inline', marginLeft: '5px', margin: 0 }}
                  >
                    {' '}
                    {total_flow === 0 ? 'N/A' : Math.round(total_flow)} gal
                  </H5>
                </Row>
                <Row
                  alignItems="left"
                  style={{
                    whiteSpace: 'nowrap',
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                  }}
                />
              </Col>
            </Row>

            <Col style={{ height: 300 }} justifyContent="center">
              <ResponsiveContainer width="100%" height={280} id="totalGaloonsChart">
                {!device_id ? (
                  <Col style={{ height: 250 }} justifyContent="center">
                    <ListEmpty message="Installation Not Complete" />
                  </Col>
                ) : (
                  <BarChart
                    data={consumption}
                    margin={{ right: 20, left: -15, bottom: 0 }}
                    barSize={20}
                  >
                    <XAxis
                      dataKey="name"
                      stroke={Colors.gray}
                      tick={{ fontSize: 12 }}
                      scale="point"
                      padding={{ left: 15, top: 15 }}
                    />

                    <YAxis type="number" unit="" stroke={Colors.gray} tick={{ fontSize: 12 }} />
                    <Tooltip content={<CustomTooltip />} />

                    <CartesianGrid strokeDasharray="3 3" />

                    <Bar dataKey="gal">
                      {consumption.map((entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill="#97D977"
                          background={{ fill: 'transparent' }}
                        />
                      ))}
                    </Bar>

                    <Bar dataKey="galInlet">
                      {consumption.map((entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={Colors.lightBlue}
                          background={{ fill: 'transparent' }}
                        />
                      ))}
                    </Bar>
                  </BarChart>
                )}
              </ResponsiveContainer>
              {last_updated && (
                <H5 color={Colors.gray8}>
                  Last updated: {moment.unix(last_updated).format('h:mma MM/DD/YYYY')}
                </H5>
              )}
            </Col>
          </GraphContainer>
        )}

        <LineGray style={{ marginTop: 15, marginBottom: 15 }} />

        {totalConsumptionRoLoading ? (
          <GraphContainer>
            <Loader size={50} block />
          </GraphContainer>
        ) : (
          <Row style={{ minHeight: 75, width: '80%', margin: 'auto' }}>
            <Col xs={100} md={100} sm={50} lg={50}>
              <H4
                textAlign="center"
                weight="bold"
                marginTop={5}
                style={{ letterSpacing: 1, lineHeight: 1 }}
              >
                Total Permeate Flow
              </H4>
              <H5 weight="bold" color={Colors.lightBlue} marginTop={5}>
                since membrane change (
                {last_membrane_change_date
                  ? moment(last_membrane_change_date).format('MM/DD/YYYY')
                  : 'The membrane has not yet been replaced'}
                )
              </H5>
            </Col>
            <Col xs={100} md={100} sm={30} lg={30}>
              <H2 color={Colors.blue}>
                {totalConsumptionRo.reverseOsmosisTotalPermeateConsumption === 0
                  ? 'N/A'
                  : Math.round(totalConsumptionRo.reverseOsmosisTotalPermeateConsumption)}
              </H2>
            </Col>
            <Col xs={100} md={100} sm={20} lg={20}>
              <H2> gal</H2>
            </Col>
          </Row>
        )}
      </>
    );
  };

  renderMembraneLife = () => {
    const {
      location: {
        ReverseOsmosisFilterHead: { last_membrane_change_date },
      },
    } = this.props;

    return (
      <BG>
        <GraphContainer>
          <Row>
            <Col lg={50} md={50} sm={100} xs={100}>
              <ResponsiveContainer height={90} width="100%">
                <PieChart>
                  <Pie
                    activeIndex={0}
                    activeShape={this.renderActiveShape}
                    // data={dataMembraneChange}
                    dataKey="gal"
                    innerRadius={32}
                    outerRadius={44}
                  />
                </PieChart>
              </ResponsiveContainer>
            </Col>
            <Col lg={50} md={50} sm={100} xs={100}>
              <H4 color={Colors.gray}>Membrane Life</H4>
            </Col>
          </Row>
          <Col>
            <H3 textAlign="center" color={Colors.gray} style={{ marginBottom: 0, marginTop: 7 }}>
              Date Last Update: {moment(last_membrane_change_date).format('h:mma MM/DD/YYYY')}
            </H3>
          </Col>
        </GraphContainer>
      </BG>
    );
  };

  renderFilterCartridges = catridgeSelectedObjet => {
    const { carbonCapacity } = this.state;

    if (catridgeSelectedObjet && catridgeSelectedObjet.last_replaced) {
      const {
        SpotsReverseOsmosisFilter: {
          Catridge: { warranty: warrantyYears },
        },
      } = catridgeSelectedObjet;
      // Calcule weeks to expire
      const filterWeeksDuration = 52 * warrantyYears;
      const lastReplacedMoment = moment(catridgeSelectedObjet.last_replaced);
      const replacedWeeks = moment().diff(lastReplacedMoment, 'weeks');
      const {
        /*  cartridgeTotalConsumptionRoLoading,
        cartridgeTotalConsumptionRo, */
        totalConsumptionRoLoading,
        totalConsumptionRo,
      } = this.props;
      // Calcule flow to expire
      const consuptionPercent = Math.round(
        (totalConsumptionRo.reverseOsmosisTotalInletConsumption * 100) / carbonCapacity,
      );

      const dataFilterExhaustion = [
        { name: 'nocomplete', value: 100 - consuptionPercent, fill: Colors.grayChart },
        { name: 'complete', value: consuptionPercent, fill: Colors.green },
      ];

      return (
        <Row style={{ minHeight: 280 }}>
          <Col lg={100} md={100} sm={100} xs={100}>
            {totalConsumptionRoLoading ? (
              <Loader size={90} block />
            ) : (
              <Row>
                <Row>
                  <Col lg={100} md={100} sm={100} xs={100}>
                    <H4 color={Colors.gray} style={{ textAlign: 'center' }}>
                      Summary cartridges:{' '}
                      <span style={{ color: 'black' }}>
                        {Math.round(totalConsumptionRo.reverseOsmosisTotalInletConsumption)} /
                        {carbonCapacity} gal
                      </span>
                    </H4>

                    <H5 color={Colors.gray}>
                      Last Replaced:{' '}
                      {moment(catridgeSelectedObjet.last_replaced).format('MMMM Do yyyy')}
                    </H5>
                  </Col>
                </Row>

                <Row>
                  <Col lg={100} md={100} sm={100} xs={100}>
                    <ResponsiveContainer height={180} width="100%" id="exhaustionChart">
                      <PieChart>
                        <Pie
                          activeIndex={0}
                          activeShape={this.renderActiveShape}
                          data={dataFilterExhaustion}
                          dataKey="value"
                          innerRadius={45}
                          outerRadius={65}
                        />
                      </PieChart>
                    </ResponsiveContainer>
                  </Col>
                </Row>
              </Row>
            )}
          </Col>

          <Col lg={50} md={50} sm={100} xs={100}>
            <Row>
              <Col lg={100} md={100} sm={100} xs={100}>
                <H4 color={Colors.gray} lineHeight="1" style={{ margin: 5 }}>
                  Expiration:
                </H4>
                <H4 color={Colors.gray} lineHeight="1" style={{ margin: 5 }}>
                  <span style={{ color: Colors.black }}>Installed For: </span> {replacedWeeks}
                  {replacedWeeks !== 1 ? ' weeks' : ' week'}
                </H4>
                <H2 textAlign="center" color={Colors.yellow} lineHeight="1" style={{ margin: 5 }}>
                  {filterWeeksDuration - replacedWeeks} weeks remaining
                </H2>
              </Col>
            </Row>
          </Col>
        </Row>
      );
    }
    return (
      <ResponsiveContainer height={325} width="100%">
        <Row justifyContent="center" alignItems="center" style={{ height: '100%' }}>
          <ListEmpty message="This cartridge has not been replaced yet" />
        </Row>
      </ResponsiveContainer>
    );
  };

  switchModalMembrane = value => {
    this.setState({ modalMembrane: value });
  };

  switchModalCartridges = value => {
    this.setState({ modalCartridges: value });
  };

  changeMembrane = () => {
    const {
      changeMembrane,
      location: { ReverseOsmosisFilterHead },
    } = this.props;
    const id = ReverseOsmosisFilterHead.FlowMeterOfROFilters[0].reverse_osmosis_filter_head_id;
    const { membraneChangeDate } = this.state;
    const membraneChangeDateUTC = moment(membraneChangeDate)
      .utc()
      .format('YYYY-MM-DD HH:mm:ss');

    changeMembrane(id, membraneChangeDateUTC);
  };

  downloadPDF = () => {
    const {
      totalGaloonsChart,
      averageChart,
      wasteChart,
      downloadPDF,
      datePickerFromConsumption,
      datePickerToConsumption,
      datePickerFromTds,
      datePickerToTds,
      datePickerFromWaste,
      datePickerToWaste,
      exhaustionChart,
      catridgeSelectedObjet,
      carbonCapacity,
    } = this.state;

    const {
      location,
      allAlerts,
      reverseOsmosisLastHourTds,
      reverseOsmosisCurrentState,
      reverseOsmosisConsumption,
      totalConsumptionRo,
      reverseOsmosisCurrentBypass,
      reverseOsmosisCurrentPressure: { inletPressure, tankPressure, systemPressure },
      reverseOsmosisCurrentTemperature,
    } = this.props;

    if (downloadPDF) {
      return (
        <PDFDownloadLink
          document={
            <ReportRO
              totalGaloonsChart={totalGaloonsChart}
              averageChart={averageChart}
              wasteChart={wasteChart}
              datePickerFromConsumption={datePickerFromConsumption}
              datePickerToConsumption={datePickerToConsumption}
              datePickerFromTds={datePickerFromTds}
              datePickerToTds={datePickerToTds}
              datePickerFromWaste={datePickerFromWaste}
              datePickerToWaste={datePickerToWaste}
              allAlerts={allAlerts}
              location={location}
              reverseOsmosisCurrentState={reverseOsmosisCurrentState}
              reverseOsmosisLastHourTds={reverseOsmosisLastHourTds}
              carbonCapacity={carbonCapacity}
              reverseOsmosisConsumption={reverseOsmosisConsumption}
              catridgeSelectedObjet={catridgeSelectedObjet}
              exhaustionChart={exhaustionChart}
              totalConsumptionRo={totalConsumptionRo}
              reverseOsmosisCurrentBypass={reverseOsmosisCurrentBypass}
              inletPressure={inletPressure}
              tankPressure={tankPressure}
              systemPressure={systemPressure}
              reverseOsmosisCurrentTemperature={reverseOsmosisCurrentTemperature}
            />
          }
          fileName="ReportRO.pdf"
        >
          {({ loading }) => (
            <H4
              color={Colors.lightBlue}
              style={{ cursor: 'pointer' }}
              onClick={() => this.setState({ generatePDF: false, downloadPDF: false })}
            >
              <Icon
                name={loading ? 'far fa-spinner' : 'far fa-file-download'}
                color={Colors.lightBlue}
                customStyle={{ marginRight: '5px' }}
              />
              {loading ? 'Loading' : 'Download'} Report
            </H4>
          )}
        </PDFDownloadLink>
      );
    }
    return (
      <Row>
        <Loader size={35} block />
        <H4 color={Colors.lightBlue} marginTop={5}>
          Generating PDF ...
        </H4>
      </Row>
    );
  };

  render() {
    const CustomTooltip = ({ payload, active, customLabel }) => {
      if (active && payload && payload[0]) {
        const currentPayload = payload[0].payload;
        return (
          <div
            style={{
              backgroundColor: 'white',
              padding: 1,
              borderRadius: 5,
              borderColor: 'gray',
              borderStyle: 'solid',
              borderWidth: 1,
            }}
          >
            <p style={{ color: 'gray' }}>{currentPayload.tooltipLabel}</p>
            <p style={{ color: 'gray' }}>
              {Math.round(currentPayload.gal)} {customLabel}
            </p>
          </div>
        );
      }

      return null;
    };

    const {
      location: {
        id: location_id,
        ReverseOsmosisFilterHead,
        Account: {
          Brand: {
            out_of_compliance,
            near_compliance_1_end,
            in_compliance_end,
            near_compliance_2_end,
            out_of_compliance_2_end,
          },
        },
      },
      renderInstructionsModal,
      reverseOsmosisCurrentState,
      reverseOsmosisTds,
      reverseOsmosisLastHourTds,
      reverseOsmosisWaste,
      reverseOsmosisTdsLoading,
      reverseOsmosisConsumptionLoading,
      reverseOsmosisCurrentStateLoading,
      reverseOsmosisWasteLoading,
      totalConsumptionReverseOsmosisLoading,
      cartridgeTotalConsumptionRoLoading,
      getCsvFiles,
      csvFiles,
      csvFilesLoading,
      setDeviceId,
      role_id,
      reverseOsmosisCurrentPressure: { inletPressure, tankPressure, systemPressure },
      reverseOsmosisCurrentPressureLoading,
      reverseOsmosisCurrentTemperature,
      reverseOsmosisCurrentTemperatureLoading,
    } = this.props;

    const {
      cartridgesChangeDate,
      membraneChangeDate,
      modalMembrane,
      modalCartridges,
      generatePDF,
      catridgeSelectedId,
      catridgeSelectedObjet,
      datePickerFromConsumption,
      datePickerToConsumption,
      modalDatePickerConsumption,
      modalDatePickerWaste,
      modalDatePickerTds,
      datePickerFromTds,
      datePickerToTds,
      datePickerFromWaste,
      datePickerToWaste,
      possibleDayRangesConsumption,
      possibleDayRangesWaste,
      possibleDayRangesTds,
      modalDatePickerCsv,
      datePickerFromCsv,
      datePickerToCsv,
      csvType,
      new_device_id,
      modalDeviceId,
      modalDatePickerReport,
      datePickerFromReport,
      datePickerToReport,
      modalDatePickerBypass,
      modalBypass,
      datePickerFromBypass,
      datePickerToBypass,
    } = this.state;

    const {
      FlowMeterOfROFilters,
      MembraneChanges: membranesReplaced,
      ReverseOsmosisFilterConfiguration,
      device_id,
      installed_date,
      last_membrane_change_date,
    } = ReverseOsmosisFilterHead;
    const { ReverseOsmosisFilterInstallSteps } = ReverseOsmosisFilterConfiguration;
    const cartridgesOfROChanges = FlowMeterOfROFilters[0].CartridgesOfROChanges;

    const getDataCompleted =
      !reverseOsmosisConsumptionLoading &&
      !reverseOsmosisCurrentStateLoading &&
      !reverseOsmosisWasteLoading &&
      !reverseOsmosisTdsLoading &&
      !totalConsumptionReverseOsmosisLoading &&
      !cartridgeTotalConsumptionRoLoading;
    return (
      <Container>
        <Row>
          <Col lg={60} md={60} sm={100} xs={100}>
            <Row justifyContent="flex-start">
              <H5 color={Colors.black} marginLeft={15}>
                Current TDS:
              </H5>
              {reverseOsmosisCurrentStateLoading ? (
                <H5>Loading...</H5>
              ) : (
                <H5 color={Colors.green} marginRight={2}>
                  {reverseOsmosisCurrentState.tds_value || 'N/A'}
                </H5>
              )}
              <H5 color={Colors.black} marginLeft={15}>
                Device ID: {device_id || 'Installation Not Complete'}
              </H5>
            </Row>
          </Col>
          <Col lg={13} md={13} sm={100} xs={100} alignItems="flex-end">
            <H4
              onClick={() => this.switchModal('showConfiguration')}
              weight={350}
              color={Colors.lightBlue}
              style={{ cursor: 'pointer' }}
            >
              Open configuration
            </H4>
          </Col>

          <Col lg={13} md={13} sm={100} xs={100} alignItems="flex-end">
            <H4
              weight={350}
              color={Colors.lightBlue}
              onClick={() => {
                this.setState({ membraneChangeDate: new Date() });
                this.switchModalMembrane(!modalMembrane);
              }}
              style={{ cursor: 'pointer' }}
            >
              <Icon
                name="far fa-exchange-alt"
                color={Colors.lightBlue}
                customStyle={{ marginRight: '5px' }}
              />
              Change Membrane
            </H4>
          </Col>
          <Col lg={13} md={13} sm={100} xs={100} alignItems="flex-end">
            {generatePDF
              ? this.downloadPDF()
              : getDataCompleted && (
                  <H4
                    weight={350}
                    color={Colors.lightBlue}
                    style={{ cursor: 'pointer' }}
                    onClick={() => this.switchModal('modalDatePickerReport')}
                  >
                    <Icon
                      name="far fa-newspaper"
                      color={Colors.lightBlue}
                      customStyle={{ marginRight: '5px' }}
                    />
                    Generate report
                  </H4>
                )}
          </Col>
        </Row>
        <LineGray />
        <Row style={{ marginBottom: 5, marginTop: 5 }}>
          <Col lg={100} md={100} sm={100} xs={100} alignItems="start">
            <H5 color={Colors.gray8}>
              {'Installed date: '}
              {installed_date
                ? `${moment(installed_date).format('MMMM Do yyyy')}`
                : 'Installation Not Complete'}
            </H5>
          </Col>
        </Row>
        <LineGray />

        <Col alignItems="flex-start" style={{ marginBottom: 10, marginTop: 10 }}>
          <Row justifyContent="end">
            {ReverseOsmosisFilterInstallSteps.length !== 0 && (
              <H5
                style={{ margin: 10 }}
                onClick={() => this.switchModal('modalViewInstructions')}
                cursor="pointer"
                color={Colors.lightBlue}
              >
                <Icon name="tasks" color={Colors.lightBlue} customStyle={{ marginRight: '5px' }} />
                View Instructions
              </H5>
            )}
            <H5
              style={{ margin: 10 }}
              onClick={() => {
                this.switchModal('modalDatePickerCsv');
                getCsvFiles({ type: 'reverse-osmosis' });
              }}
              cursor="pointer"
              color={Colors.lightBlue}
            >
              <Icon
                name="file-export"
                color={Colors.lightBlue}
                customStyle={{ marginRight: '5px' }}
              />
              Export Data
            </H5>
            {role_id === 1 && (
              <>
                <H5
                  style={{ margin: 10 }}
                  onClick={() => {
                    this.switchModal('modalDeviceId');
                  }}
                  cursor="pointer"
                  color={Colors.lightBlue}
                >
                  <Icon
                    name="pen-square"
                    color={Colors.lightBlue}
                    customStyle={{ marginRight: '5px' }}
                  />
                  Set Device ID
                </H5>
                <H5
                  style={{ margin: 10 }}
                  onClick={() => {
                    this.setState({ cartridgesChangeDate: new Date() });
                    this.switchModalCartridges(!modalCartridges);
                  }}
                  cursor="pointer"
                  color={Colors.lightBlue}
                >
                  <Icon name="sync" color={Colors.lightBlue} customStyle={{ marginRight: '5px' }} />
                  Replace Cartridges
                </H5>
              </>
            )}
          </Row>
        </Col>
        <LineGray />
        <Row style={{ marginTop: 10 }}>
          <Col lg={50} md={100} sm={100} xs={100}>
            <BG>
              <Row style={{ marginBottom: 15 }}>
                <Col lg={50} md={50} sm={50} xs={50}>
                  <Row
                    justifyContent="flex-start"
                    alignItems="center"
                    data
                    style={{ cursor: 'pointer' }}
                  >
                    {possibleDayRangesConsumption.map(range => (
                      <H5
                        cursor="pointer"
                        color={
                          range === this.state.rangeConsumption ? Colors.lightBlue : Colors.gray
                        }
                        style={{ marginLeft: 5, marginRight: 5 }}
                        onClick={() => this.calculeReverseOsmosisConsumption(range)}
                      >
                        {range}
                      </H5>
                    ))}
                  </Row>
                </Col>
              </Row>
              {this.renderReverseOsmosisConsumptions()}
            </BG>
          </Col>

          <Col lg={50} md={100} sm={100} xs={100}>
            <BG>
              <GraphContainer height={395}>
                <Col>
                  <H2 textAlign="center">RO Filter Cartridges</H2>
                  {this.renderFilterCartridges(catridgeSelectedObjet)}
                  <LineGray />

                  <Row>
                    <FilterGroup>
                      <FilterContainer>
                        <Filter
                          title="Cartridge:"
                          selectedValue={catridgeSelectedId}
                          name="catridgeSelectedId"
                          options={FlowMeterOfROFilters.map(fm => ({
                            label: `${fm.SpotsReverseOsmosisFilter.position} - ${fm.SpotsReverseOsmosisFilter.Catridge.name}`,
                            value: fm.id,
                          }))}
                          handleChange={this.cartidgeOnChange}
                        />
                      </FilterContainer>
                    </FilterGroup>
                  </Row>
                </Col>
              </GraphContainer>
            </BG>
            <BG>
              <GraphContainer height={140} display="flex">
                <Row>{this.renderBypassFrequency()}</Row>
              </GraphContainer>
            </BG>
          </Col>
        </Row>
        <Row>
          <Col lg={75} md={100} sm={100} xs={100} justifyContent="center">
            <Row>
              <BG>
                <GraphContainer height={280}>
                  <Col>
                    <H2>Average TDS</H2>
                    <H5 style={{ color: Colors.gray }}>
                      Past hour:
                      <span style={{ color: Colors.blue, marginBottom: 5 }}>
                        {reverseOsmosisLastHourTds || 'N/A'} ppm
                      </span>
                    </H5>

                    <Row style={{ marginBottom: 15 }}>
                      <Col lg={50} md={50} sm={50} xs={50}>
                        <Row
                          justifyContent="flex-start"
                          alignItems="center"
                          data
                          style={{ cursor: 'pointer' }}
                        >
                          {possibleDayRangesTds.map(range => (
                            <H5
                              cursor="pointer"
                              color={range === this.state.rangeTds ? Colors.lightBlue : Colors.gray}
                              style={{ marginLeft: 5, marginRight: 5 }}
                              onClick={() => this.calculeReverseOsmosisTds(range)}
                            >
                              {range}
                            </H5>
                          ))}
                        </Row>
                      </Col>

                      <Col lg={50} md={50} sm={50} xs={50}>
                        <Row
                          justifyContent="flex-end"
                          alignItems="center"
                          data
                          style={{ cursor: 'pointer' }}
                          onClick={() => this.switchModal('modalDatePickerTds')}
                        >
                          <H5 color={Colors.lightBlue} style={{ margin: 7 }}>
                            {moment(datePickerFromTds).format('MM/DD/YYYY')}-
                            {moment(datePickerToTds).format('MM/DD/YYYY')}
                          </H5>
                          <Icon
                            name="calendar-alt"
                            color={Colors.lightBlue}
                            customStyle={{ fontSize: '20px' }}
                          />
                        </Row>
                      </Col>
                    </Row>
                    {reverseOsmosisTdsLoading ? (
                      <Loader size={125} block />
                    ) : (
                      <ResponsiveContainer width="100%" height={200} id="averageChart">
                        {!device_id ? (
                          <Col justifyContent="center">
                            <ListEmpty message="Installation Not Complete" />
                          </Col>
                        ) : (
                          <LineChart
                            data={reverseOsmosisTds}
                            margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
                          >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name" stroke={Colors.gray} />
                            <YAxis stroke={Colors.gray} />
                            <Tooltip customLabel="PPM" content={<CustomTooltip />} />
                            <Line
                              type="monotone"
                              dataKey="gal"
                              stroke={Colors.lightBlue}
                              fill={Colors.lightBlue}
                            />
                            <ReferenceArea
                              y1={0}
                              y2={out_of_compliance}
                              ifOverflow="extendDomain"
                              fill={Colors.red}
                            />
                            <ReferenceArea
                              y1={out_of_compliance}
                              y2={near_compliance_1_end}
                              ifOverflow="extendDomain"
                              fill={Colors.yellow}
                            />
                            <ReferenceArea
                              y1={near_compliance_1_end}
                              y2={in_compliance_end}
                              ifOverflow="extendDomain"
                              fill={Colors.green}
                            />
                            <ReferenceArea
                              y1={in_compliance_end}
                              y2={near_compliance_2_end}
                              ifOverflow="extendDomain"
                              fill={Colors.yellow}
                            />
                            <ReferenceArea
                              y1={near_compliance_2_end}
                              y2={out_of_compliance_2_end}
                              ifOverflow="extendDomain"
                              fill={Colors.red}
                            />
                          </LineChart>
                        )}
                      </ResponsiveContainer>
                    )}
                  </Col>
                </GraphContainer>
              </BG>
            </Row>
          </Col>
          <Col lg={25} md={100} sm={100} xs={100}>
            {/* {this.renderMembraneLife()} */}

            <BG>
              <GraphContainer>
                <Col style={{ minHeight: 125 }} justifyContent="center">
                  {reverseOsmosisCurrentPressureLoading ? (
                    <Loader size={60} block />
                  ) : (
                    <>
                      <Row>
                        <H2 marginBottom={10}>Inlet Pressure</H2>
                        <Icon name="check-circle" color={Colors.green} fontSize={25} />
                      </Row>
                      {!device_id ? (
                        <Col justifyContent="center">
                          <ListEmpty message="Installation Not Complete" />
                        </Col>
                      ) : (
                        <Row>
                          <Icon name="weight" color={Colors.lightBlue} fontSize={52} />
                          {inletPressure !== null ? (
                            <H1>{Math.round(inletPressure)} psi</H1>
                          ) : (
                            <H1 lineHeight="0" color={Colors.blue}>
                              N/A
                            </H1>
                          )}
                        </Row>
                      )}
                    </>
                  )}
                </Col>
              </GraphContainer>
            </BG>
            <BG>
              <GraphContainer>
                <Col style={{ minHeight: 120 }} justifyContent="center">
                  {reverseOsmosisCurrentPressureLoading ? (
                    <Loader size={60} block />
                  ) : (
                    <>
                      <Row>
                        <H2 marginBottom={10}>Tank Pressure</H2>
                        <Icon name="check-circle" color={Colors.green} fontSize={24} />
                      </Row>
                      {!device_id ? (
                        <Col justifyContent="center">
                          <ListEmpty message="Installation Not Complete" />
                        </Col>
                      ) : (
                        <Row>
                          <Icon name="vials" color={Colors.lightBlue} fontSize={50} />
                          {tankPressure !== null ? (
                            <H1>{Math.round(tankPressure)} psi</H1>
                          ) : (
                            <H1 lineHeight="0" color={Colors.blue}>
                              N/A
                            </H1>
                          )}
                        </Row>
                      )}
                    </>
                  )}
                </Col>
              </GraphContainer>
            </BG>
          </Col>
        </Row>
        <Row alignItems="end">
          <Col lg={75} md={100} sm={100} xs={100} justifyContent="center">
            <Row>
              <BG>
                <GraphContainer height={280}>
                  <Col>
                    <H2 textAlign="center">Waste Water Percentage</H2>
                    <Row style={{ marginBottom: 15 }}>
                      <Col lg={50} md={50} sm={50} xs={50}>
                        <Row
                          justifyContent="flex-start"
                          alignItems="center"
                          data
                          style={{ cursor: 'pointer' }}
                        >
                          {possibleDayRangesWaste.map(range => (
                            <H5
                              cursor="pointer"
                              color={
                                range === this.state.rangeWaste ? Colors.lightBlue : Colors.gray
                              }
                              style={{ marginLeft: 5, marginRight: 5 }}
                              onClick={() => this.calculeReverseOsmosisWaste(range)}
                            >
                              {range}
                            </H5>
                          ))}
                        </Row>
                      </Col>

                      <Col lg={50} md={50} sm={50} xs={50}>
                        <Row
                          justifyContent="flex-end"
                          alignItems="center"
                          data
                          style={{ cursor: 'pointer' }}
                          onClick={() => this.switchModal('modalDatePickerWaste')}
                        >
                          <H5 color={Colors.lightBlue} style={{ margin: 7 }}>
                            {moment(datePickerFromWaste).format('MM/DD/YYYY')}-
                            {moment(datePickerToWaste).format('MM/DD/YYYY')}
                          </H5>
                          <Icon
                            name="calendar-alt"
                            color={Colors.lightBlue}
                            customStyle={{ fontSize: '20px' }}
                          />
                        </Row>
                      </Col>
                    </Row>
                    {reverseOsmosisWasteLoading ? (
                      <Loader size={125} block />
                    ) : (
                      <ResponsiveContainer width="100%" height={200} id="wasteChart">
                        {!device_id ? (
                          <Col justifyContent="center">
                            <ListEmpty message="Installation Not Complete" />
                          </Col>
                        ) : (
                          <LineChart
                            data={reverseOsmosisWaste}
                            syncId="anyId"
                            margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
                          >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name" stroke={Colors.gray} />
                            <YAxis stroke={Colors.gray} />
                            <Tooltip customLabel="%" content={<CustomTooltip />} />
                            <Line
                              type="monotone"
                              dataKey="gal"
                              stroke={Colors.lightBlue}
                              fill={Colors.lightBlue}
                            />

                            {/* Green zone (40-60%) */}
                            <ReferenceArea
                              y1={30}
                              y2={70}
                              ifOverflow="extendDomain"
                              fill={Colors.green}
                            />

                            {/* Yellow zones (20-40% and 60-80%) */}
                            <ReferenceArea
                              y1={10}
                              y2={30}
                              ifOverflow="extendDomain"
                              fill={Colors.yellow}
                            />
                            <ReferenceArea
                              y1={70}
                              y2={90}
                              ifOverflow="extendDomain"
                              fill={Colors.yellow}
                            />

                            {/* Red zones (0-20% and 80-100%) */}
                            <ReferenceArea
                              y1={0}
                              y2={10}
                              ifOverflow="extendDomain"
                              fill={Colors.red}
                            />
                            <ReferenceArea
                              y1={90}
                              y2={100}
                              ifOverflow="extendDomain"
                              fill={Colors.red}
                            />
                          </LineChart>
                        )}
                      </ResponsiveContainer>
                    )}
                  </Col>
                </GraphContainer>
              </BG>
            </Row>
          </Col>
          <Col lg={25} md={100} sm={100} xs={100}>
            <BG>
              <GraphContainer>
                <Col style={{ minHeight: 120 }} justifyContent="center">
                  {reverseOsmosisCurrentPressureLoading ? (
                    <Loader size={60} block />
                  ) : (
                    <>
                      <Row>
                        <H2 marginBottom={10}>System Pressure</H2>
                        <Icon name="check-circle" color={Colors.green} fontSize={24} />
                      </Row>
                      {!device_id ? (
                        <Col justifyContent="center">
                          <ListEmpty message="Installation Not Complete" />
                        </Col>
                      ) : (
                        <Row>
                          <Icon name="vials" color={Colors.lightBlue} fontSize={50} />
                          {systemPressure !== null ? (
                            <H1>{Math.round(systemPressure)} psi</H1>
                          ) : (
                            <H1 lineHeight="0" color={Colors.blue}>
                              N/A
                            </H1>
                          )}
                        </Row>
                      )}
                    </>
                  )}
                </Col>
              </GraphContainer>
            </BG>
            <BG>
              <GraphContainer>
                <Col style={{ minHeight: 120 }} justifyContent="center">
                  {reverseOsmosisCurrentTemperatureLoading ? (
                    <Loader size={100} block />
                  ) : (
                    <>
                      <Row>
                        <H2 marginBottom={10}>Temperature</H2>
                        <Icon name="check-circle" color={Colors.green} fontSize={25} />
                      </Row>

                      {!device_id ? (
                        <Col justifyContent="center">
                          <ListEmpty message="Installation Not Complete" />
                        </Col>
                      ) : (
                        <Row>
                          <Icon name="thermometer-half" color={Colors.lightBlue} fontSize={52} />
                          {reverseOsmosisCurrentTemperature ? (
                            <H1>
                              {reverseOsmosisCurrentTemperature}
                              ºF
                            </H1>
                          ) : (
                            <H1 lineHeight="0" color={Colors.blue}>
                              N/A
                            </H1>
                          )}
                        </Row>
                      )}
                    </>
                  )}
                </Col>
              </GraphContainer>
            </BG>
          </Col>
        </Row>
        <Modal
          title="Membrane change"
          onClose={() => {
            this.setState({ membraneChangeDate: '' });
            this.switchModalMembrane(!modalMembrane);
          }}
          isVisible={modalMembrane}
          size="small"
        >
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <H4 color={Colors.gray4}>Change date</H4>
            <DatePickerComponent
              minDate={moment(installed_date).toDate()}
              date={membraneChangeDate}
              onChange={value => this.handleDate(value, 'membraneChangeDate')}
              style={{ width: '100%' }}
            />
          </div>

          <Row
            style={{
              marginTop: 10,
              marginBottom: 10,
              gap: 10,
              flexWrap: 'wrap',
              justifyContent: 'center',
            }}
          >
            <ButtonText
              text="Cancel"
              theme="secondary"
              type="submit"
              onClick={() => {
                this.setState({ membraneChangeDate: '' });
                this.switchModalMembrane(!modalMembrane);
              }}
            />
            <ButtonText
              text="Confirm"
              theme="primary"
              type="submit"
              disabled={!membraneChangeDate}
              onClick={() => {
                this.changeMembrane();
                this.setState({ membraneChangeDate: '' });
                this.switchModalMembrane(!modalMembrane);
              }}
            />
          </Row>

          {membranesReplaced && membranesReplaced.length > 0 && (
            <>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  padding: 5,
                }}
              >
                <H2 color={Colors.blue}>Change History</H2>
              </div>

              <Row style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                {membranesReplaced.map(membrane => (
                  <Col
                    key={membrane.id}
                    style={{ display: 'flex', alignItems: 'start', width: '70%' }}
                  >
                    <LineGray />
                    <Row
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        padding: 5,
                        width: '100%',
                      }}
                    >
                      <H5 color={Colors.blue}>
                        {moment(membrane.created_at).format('MM/DD/YYYY')}
                      </H5>
                      <H5 style={{ marginLeft: 3 }} color={Colors.blue}>
                        {membrane.User ? membrane.User.email : 'Unknown'}
                      </H5>
                    </Row>
                  </Col>
                ))}
              </Row>
            </>
          )}
        </Modal>

        <Modal
          title="Replace Cartridges"
          onClose={() => this.switchModalCartridges(!modalCartridges)}
          isVisible={modalCartridges}
          size="small"
        >
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <H4 color={Colors.gray4}>Changed date</H4>
            <DatePickerComponent
              minDate={moment(installed_date).toDate()}
              date={cartridgesChangeDate}
              onChange={value => this.handleDate(value, 'cartridgesChangeDate')}
              style={{ width: '100%' }}
            />
          </div>
          <Row
            style={{
              marginTop: 30,
              marginBottom: 10,
              gap: 10,
              flexWrap: 'wrap',
              justifyContent: 'center',
            }}
          >
            <ButtonText
              text="Cancel"
              theme="secondary"
              type="submit"
              onClick={() => this.switchModalCartridges(!modalCartridges)}
            />
            <ButtonText
              text="Confirm"
              theme="primary"
              type="submit"
              disabled={!cartridgesChangeDate}
              onClick={() => {
                this.replaceReverseOsmosisCartridgesFunc();
                this.switchModalCartridges(!modalCartridges);
              }}
            />
          </Row>
          {cartridgesOfROChanges && cartridgesOfROChanges.length > 0 && (
            <>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  padding: 5,
                }}
              >
                <H2 color={Colors.blue}>Change History</H2>
              </div>
              <Row style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                {cartridgesOfROChanges.map(cartridge => (
                  <Col
                    key={cartridge.id}
                    style={{ display: 'flex', alignItems: 'start', width: '70%' }}
                  >
                    <LineGray />
                    <Row
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        padding: 5,
                        width: '100%',
                      }}
                    >
                      <H5 color={Colors.blue}>
                        {moment(cartridge.created_at).format('MM/DD/YYYY')}
                      </H5>
                      <H5 style={{ marginLeft: 3 }} color={Colors.blue}>
                        {cartridge.User ? cartridge.User.email : 'Unknown'}
                      </H5>
                    </Row>
                  </Col>
                ))}
              </Row>
            </>
          )}
        </Modal>

        <ModalDatePicker
          minDate={moment(last_membrane_change_date).toDate()}
          isVisible={modalDatePickerConsumption}
          handleDateFrom={value => this.handleDate(value, 'datePickerFromConsumption')}
          handleDateTo={value => this.handleDate(value, 'datePickerToConsumption')}
          switchModal={() => this.switchModal('modalDatePickerConsumption')}
          dateFrom={datePickerFromConsumption}
          dateTo={datePickerToConsumption}
          saveChanges={() => {
            this.switchModal('modalDatePickerConsumption');
            this.calculeReverseOsmosisConsumption();
          }}
        />
        <ModalCsvFiles
          minDate={moment(installed_date).toDate()}
          isVisible={modalDatePickerCsv}
          handleDateFrom={value => this.handleDate(value, 'datePickerFromCsv')}
          handleDateTo={value => this.handleDate(value, 'datePickerToCsv')}
          switchModal={() => this.switchModal('modalDatePickerCsv')}
          dateFrom={datePickerFromCsv}
          dateTo={datePickerToCsv}
          changeCsvType={this.changeCsvType}
          csvType={csvType}
          saveChanges={() => {
            this.switchModal('modalDatePickerCsv');
            this.generateReverseOsmosisCsv();
          }}
          csvFiles={csvFiles}
          csvFilesLoading={csvFilesLoading}
        />
        <ModalDatePicker
          minDate={moment(installed_date).toDate()}
          isVisible={modalDatePickerTds}
          handleDateFrom={value => this.handleDate(value, 'datePickerFromTds')}
          handleDateTo={value => this.handleDate(value, 'datePickerToTds')}
          switchModal={() => this.switchModal('modalDatePickerTds')}
          dateFrom={datePickerFromTds}
          dateTo={datePickerToTds}
          saveChanges={() => {
            this.switchModal('modalDatePickerTds');
            this.calculeReverseOsmosisTds();
          }}
        />
        <ModalDatePicker
          minDate={moment(installed_date).toDate()}
          isVisible={modalDatePickerWaste}
          handleDateFrom={value => this.handleDate(value, 'datePickerFromWaste')}
          handleDateTo={value => this.handleDate(value, 'datePickerToWaste')}
          switchModal={() => this.switchModal('modalDatePickerWaste')}
          dateFrom={datePickerFromWaste}
          dateTo={datePickerToWaste}
          saveChanges={() => {
            this.switchModal('modalDatePickerWaste');
            this.calculeReverseOsmosisWaste();
          }}
        />
        {this.renderConfigurationModal()}
        <Modal
          title="Update Device ID (Serial Number)"
          onClose={() => this.switchModal('modalDeviceId')}
          isVisible={modalDeviceId}
          size="small"
        >
          <Row>
            <Col>
              <Input
                placeholder="Device ID"
                value={new_device_id}
                onChange={this.handleChangeDeviceId}
                style={{ marginBottom: '20px' }}
              />
              <ButtonText
                text="Update"
                theme="primary"
                onClick={() =>
                  setDeviceId({ type: 'reverse-osmosis', device_id: new_device_id, location_id })
                }
              />
            </Col>
          </Row>
        </Modal>
        <ModalDatePicker
          minDate={moment(installed_date).toDate()}
          isVisible={modalDatePickerReport}
          handleDateFrom={value => this.handleDate(value, 'datePickerFromReport')}
          handleDateTo={value => this.handleDate(value, 'datePickerToReport')}
          switchModal={() => this.switchModal('modalDatePickerReport')}
          dateFrom={datePickerFromReport}
          dateTo={datePickerToReport}
          saveChanges={() => {
            this.switchModal('modalDatePickerReport');
            this.generateReport();
          }}
        />

        <ModalDatePicker
          minDate={moment(installed_date).toDate()}
          isVisible={modalDatePickerBypass}
          handleDateFrom={value => this.handleDate(value, 'datePickerFromBypass')}
          handleDateTo={value => this.handleDate(value, 'datePickerToBypass')}
          switchModal={() => this.switchModal('modalDatePickerBypass')}
          dateFrom={datePickerFromBypass}
          dateTo={datePickerToBypass}
          saveChanges={() => {
            this.switchModal('modalDatePickerBypass');
            this.calculeWaterFilterBypass();
          }}
        />

        <Modal
          title="Bypass Flow"
          onClose={() => this.switchModal('modalBypass')}
          isVisible={modalBypass}
          size="medium"
        >
          {this.renderBypassConsumptions()}
        </Modal>

        {renderInstructionsModal(
          this.state.modalViewInstructions,
          () => this.switchModal('modalViewInstructions'),
          ReverseOsmosisFilterInstallSteps,
        )}
      </Container>
    );
  }
}

function mapStateToProps(state) {
  return {
    reverseOsmosisCurrentState: state.locations.reverseOsmosisCurrentState,
    reverseOsmosisConsumption: state.locations.reverseOsmosisConsumption,
    reverseOsmosisConsumptionLoading: state.locations.reverseOsmosisConsumptionLoading,
    totalConsumptionReverseOsmosisLoading: state.locations.totalConsumptionReverseOsmosisLoading,
    reverseOsmosisCurrentStateLoading: state.locations.reverseOsmosisCurrentStateLoading,
    reverseOsmosisTotalConsumption: state.locations.reverseOsmosisTotalConsumption,
    reverseOsmosisWaste: state.locations.reverseOsmosisWaste.waste,
    reverseOsmosisWasteLoading: state.locations.reverseOsmosisWasteLoading,
    reverseOsmosisTds: state.locations.reverseOsmosisTds.tds,
    reverseOsmosisLastHourTds: state.locations.reverseOsmosisTds.tdsLastHour,
    reverseOsmosisTdsLoading: state.locations.reverseOsmosisTdsLoading,
    csvFiles: state.locations.csvFiles,
    csvFilesLoading: state.locations.csvFilesLoading,
    totalConsumptionRoLoading: state.locations.totalConsumptionRoLoading,
    totalConsumptionRo: state.locations.totalConsumptionRo,
    cartridgeTotalConsumptionRo: state.locations.cartridgeTotalConsumptionRo,
    cartridgeTotalConsumptionRoLoading: state.locations.cartridgeTotalConsumptionRoLoading,
    ro_for_pfd: state.locations.ro_for_pfd,
    role_id: state.auth.profile.role_id,
    reverseOsmosisBypass: state.locations.reverseOsmosisBypass,
    reverseOsmosisBypassLoading: state.locations.reverseOsmosisBypassLoading,
    reverseOsmosisCurrentBypass: state.locations.reverseOsmosisCurrentBypass,
    reverseOsmosisCurrentBypassLoading: state.locations.reverseOsmosisCurrentBypassLoading,
    reverseOsmosisCurrentPressure: state.locations.reverseOsmosisCurrentPressure,
    reverseOsmosisCurrentPressureLoading: state.locations.reverseOsmosisCurrentPressureLoading,
    reverseOsmosisCurrentTemperature: state.locations.reverseOsmosisCurrentTemperature,
    reverseOsmosisCurrentTemperatureLoading:
      state.locations.reverseOsmosisCurrentTemperatureLoading,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    reverseOsmosisSetInitialState: () => dispatch(reverseOsmosisSetInitialState()),
    changeMembrane: (id, new_date) => dispatch(changeMembranes({ id, new_date })),
    getReverseOsmosisCurrentState: serialNumber =>
      dispatch(getReverseOsmosisCurrentState(serialNumber)),
    getReverseOsmosisConsumption: params => dispatch(getReverseOsmosisConsumption(params)),
    getReverseOsmosisTds: params => dispatch(getReverseOsmosisTds(params)),
    getReverseOsmosisWaste: params => dispatch(getReverseOsmosisWaste(params)),
    getTotalConsumptionReverseOsmosis: params =>
      dispatch(getTotalConsumptionReverseOsmosis(params)),
    generateReverseOsmosisCsv: params => dispatch(generateReverseOsmosisCsv(params)),
    getCsvFiles: params => dispatch(getCsvFiles(params)),
    getTotalConsumptionRo: params => dispatch(getTotalConsumptionRo(params)),
    getTotalConsumptionCartridgeRo: params => dispatch(getTotalConsumptionCartridgeRo(params)),
    replaceReverseOsmosisCartridges: params => dispatch(replaceReverseOsmosisCartridges(params)),
    getReverseOsmosisBypass: params => dispatch(getReverseOsmosisBypass(params)),
    getReverseOsmosisCurrentBypass: serialNumber =>
      dispatch(getReverseOsmosisCurrentBypass(serialNumber)),
    getReverseOsmosisCurrentPressure: params => dispatch(getReverseOsmosisCurrentPressure(params)),
    getReverseOsmosisCurrentTemperature: params =>
      dispatch(getReverseOsmosisCurrentTemperature(params)),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(RO);

RO.propTypes = {
  active: PropTypes.bool,
  allAlerts: PropTypes.array,
  cartridgeTotalConsumptionRoLoading: PropTypes.bool,
  changeMembrane: PropTypes.func,
  csvFiles: PropTypes.array,
  csvFilesLoading: PropTypes.bool,
  customLabel: PropTypes.string,
  data: PropTypes.array,
  device_id: PropTypes.string,
  fetchAllAlerts: PropTypes.func,
  generateReverseOsmosisCsv: PropTypes.func,
  getCsvFiles: PropTypes.func,
  getReverseOsmosisBypass: PropTypes.func,
  getReverseOsmosisConsumption: PropTypes.func,
  getReverseOsmosisCurrentBypass: PropTypes.func,
  getReverseOsmosisCurrentPressure: PropTypes.func,
  getReverseOsmosisCurrentState: PropTypes.func,
  getReverseOsmosisCurrentTemperature: PropTypes.func,
  getReverseOsmosisTds: PropTypes.func,
  getReverseOsmosisWaste: PropTypes.func,
  getTotalConsumptionCartridgeRo: PropTypes.func,
  getTotalConsumptionRo: PropTypes.func,
  location: PropTypes.object,
  payload: PropTypes.object,
  renderInstructionsModal: PropTypes.func,
  replaceReverseOsmosisCartridges: PropTypes.func,
  reverseOsmosisBypass: PropTypes.array,
  reverseOsmosisBypassLoading: PropTypes.bool,
  reverseOsmosisConsumption: PropTypes.array,
  reverseOsmosisConsumptionLoading: PropTypes.bool,
  reverseOsmosisCurrentBypass: PropTypes.object,
  reverseOsmosisCurrentBypassLoading: PropTypes.bool,
  reverseOsmosisCurrentPressure: PropTypes.object,
  reverseOsmosisCurrentPressureLoading: PropTypes.bool,
  reverseOsmosisCurrentState: PropTypes.object,
  reverseOsmosisCurrentStateLoading: PropTypes.bool,
  reverseOsmosisCurrentTemperature: PropTypes.number,
  reverseOsmosisCurrentTemperatureLoading: PropTypes.bool,
  reverseOsmosisLastHourTds: PropTypes.string,
  reverseOsmosisSetInitialState: PropTypes.func,
  reverseOsmosisTds: PropTypes.array,
  reverseOsmosisTdsLoading: PropTypes.bool,
  reverseOsmosisWaste: PropTypes.array,
  reverseOsmosisWasteLoading: PropTypes.bool,
  ro_for_pfd: PropTypes.object,
  role_id: PropTypes.number,
  setDeviceId: PropTypes.func,
  totalConsumptionReverseOsmosisLoading: PropTypes.bool,
  totalConsumptionRo: PropTypes.array,
  totalConsumptionRoLoading: PropTypes.bool,
};
