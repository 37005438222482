import React, { Component } from 'react';
import styled from 'styled-components';
import { Colors } from 'modules/theme';
import { push } from 'modules/history';
import InputSearch from 'components/InputSearch';
import H4 from 'components/H4';
import ButtonText from 'components/ButtonText';
import PaginationButtons from 'components/PaginationButtons';
import Table, { THeader, TableTitle, TRow, TableItem, TBody } from 'components/Table';
import Filter, { FilterGroup, FilterContainer } from 'components/Filter';
import Dropdown from 'components/Dropdown';
import AlertConfirm from 'components/AlertConfirm';
import ListEmpty from 'components/ListEmpty';
import Loader from 'components/Loader';
import Icon from 'components/Icon';
import { Tooltip } from 'react-tooltip';
import PropTypes from 'prop-types';
import moment from 'moment';

const Container = styled.div``;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-bottom: 20px;
`;

const SearchContainer = styled.div`
  padding: 10px;
  flex-direction: column;
`;

const ButtonContainer = styled.div`
  flex-direction: column;
  float: right;
`;

const ButtonGroup = styled.div`
  padding: 10px;
  flex-direction: column;
  float: right;
`;

const TableContainer = styled.div`
  padding-bottom: 50px;
  margin-bottom: 50px;
`;

const Footer = styled.div`
  position: fixed;
  bottom: 0;
  padding-left: 25px;
  padding-bottom: 25px;
  background-color: ${Colors.white};
  width: 100%;
`;

// Wrapper for the status icon to center it properly within the table cell
const StatusIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

// A colored circle container for status icons
// Green for normal operation, yellow for alerts, gray for not installed.
const StatusIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: ${props => {
    if (!props.installed) return Colors.gray;
    return props.hasAlerts ? Colors.yellow : Colors.green;
  }};
`;

export default class LocationListView extends Component {
  state = {
    dropdownOpenId: null,
    locationSelected: null,
    isOpenModal: false,
    isOpenAlert: false,
    search: '',
    brand_id: '',
    account_id: '',
    state: '',
    installed: '',
  };

  searchOnChange = search => {
    this.setState({ search }, () => {
      const { fetchLocations } = this.props;
      const { search, brand_id, account_id, state } = this.state;

      fetchLocations({ search, brand_id, account_id, state });
    });
  };

  filterOnChange = event => {
    const { name, value } = event.target;
    const { fetchLocations, fetchAccounts } = this.props;

    this.setState(
      {
        [name]: value,
        ...(name === 'brand_id' ? { account_id: '' } : {}),
      },
      () => {
        const { search, brand_id, account_id, state, installed } = this.state;

        if (name === 'brand_id') {
          fetchAccounts(value ? { brands: [value] } : {});
        }

        fetchLocations({ search, brand_id, account_id, state, installed });
      },
    );
  };

  switchDropdown = key => {
    const { dropdownOpenId } = this.state;
    const id = dropdownOpenId !== key ? key : null;

    this.setState({ dropdownOpenId: id });
  };

  switchModal = () => {
    const { isOpenModal } = this.state;

    this.setState({ isOpenModal: !isOpenModal });
  };

  switchAlert = (locationSelected = null) => {
    const { isOpenAlert } = this.state;

    this.setState({ isOpenAlert: !isOpenAlert, locationSelected });
  };

  editLocation = ({ id }) => {
    push(`/locations/${id}/edit`);
  };

  viewLocation = ({ id }) => {
    push(`/locations/${id}`);
  };

  remove = () => {
    const { removeLocation } = this.props;
    const {
      locationSelected: { id },
    } = this.state;
    removeLocation(id);
    this.switchAlert();
  };

  // Format the alert timestamp to a user-friendly format
  // Displays as MM/DD/YYYY at h:mma (ex: 03/10/2025 at 4:40am)
  formatAlertDate = dateString => {
    const date = moment(dateString);
    const formattedDate = date.format('MM/DD/YYYY');
    const formattedTime = date.format('h:mma').toLowerCase();
    return `${formattedDate} at ${formattedTime}`;
  };

  // Plain text tooltip content - fallback for no alerts
  renderAlertTooltipContent = alerts => {
    if (!alerts || alerts.length === 0) {
      return 'Operating normally';
    }

    const alert = alerts[0]; // Get the first alert
    return `Trigger Date: ${this.formatAlertDate(alert.created_at)}\n${alert.title}`;
  };

  // HTML tooltip content for better formatting of alert info
  // Shows trigger date and alert title in a more readable format
  renderAlertTooltipHTML = alerts => {
    if (!alerts || alerts.length === 0) {
      return 'Operating normally';
    }

    const alert = alerts[0]; // Get the first alert
    return `<strong>Trigger Date:</strong> ${this.formatAlertDate(alert.created_at)}<br /><strong>${
      alert.title
    }</strong>`;
  };

  renderItem = (item, key) => {
    const { role_code } = this.props;
    // Check if this location has any alerts that need to be displayed
    const hasAlerts = item.Alerts && item.Alerts.length > 0;
    const tooltipId = `location-status-${item.id}`;

    // Check if alerts are within the last two days
    const hasRecentAlerts =
      hasAlerts &&
      item.Alerts.some(alert => {
        const alertDate = moment(alert.created_at);
        const twoDaysAgo = moment().subtract(2, 'days');
        return alertDate.isAfter(twoDaysAgo);
      });

    // Get installation status
    const isInstalled = !!item.installed;

    // Determine tooltip content based on status
    let tooltipContent = 'Not installed';
    if (isInstalled) {
      tooltipContent = hasRecentAlerts
        ? this.renderAlertTooltipContent(item.Alerts)
        : 'Operating normally';
    }

    return (
      <TRow key={key}>
        <TableItem
          width="19%"
          style={{ cursor: 'pointer', color: Colors.lightBlue }}
          onClick={() => this.viewLocation(item)}
        >
          {item.identificator}
        </TableItem>
        <TableItem width="19%">{item.brand_name}</TableItem>
        <TableItem width="19%">{item.account_name}</TableItem>
        <TableItem width="14%">{item.full_address}</TableItem>
        <TableItem width="14%">{item.contact_email || item.locationAdmin?.email || '-'}</TableItem>
        {/* Status column showing alert status with icon and tooltip */}
        <TableItem width="10%" isComponent>
          <StatusIconWrapper>
            <StatusIcon
              installed={isInstalled}
              hasAlerts={hasRecentAlerts}
              data-tooltip-id={tooltipId}
              data-tooltip-html={
                hasRecentAlerts ? this.renderAlertTooltipHTML(item.Alerts) : tooltipContent
              }
            >
              <Icon
                name={!isInstalled ? 'times' : hasRecentAlerts ? 'exclamation' : 'check'}
                fontSize={12}
                color={Colors.white}
              />
            </StatusIcon>
            <Tooltip id={tooltipId} />
          </StatusIconWrapper>
        </TableItem>
        <TableItem width="5%" isComponent>
          <Dropdown
            icon="ellipsis-v"
            options={
              ['admin', 'general'].includes(role_code)
                ? [
                    {
                      title: 'View',
                      icon: 'eye',
                      color: Colors.blue,
                      action: () => this.viewLocation(item),
                    },
                    {
                      title: 'Edit',
                      icon: 'pencil-alt',
                      color: Colors.green,
                      action: () => this.editLocation(item),
                    },
                    {
                      title: 'Remove',
                      icon: 'trash',
                      color: Colors.red,
                      action: () => this.switchAlert(item),
                    },
                  ]
                : [
                    {
                      title: 'View',
                      icon: 'eye',
                      color: Colors.blue,
                      action: () => this.viewLocation(item),
                    },
                  ]
            }
            isDropdownOpen={this.state.dropdownOpenId === key}
            onClickDropdownButton={() => this.switchDropdown(key)}
          />
        </TableItem>
      </TRow>
    );
  };

  renderAlertConfirm = () => {
    const { isOpenAlert, locationSelected } = this.state;

    return (
      <AlertConfirm
        title="Remove location"
        onCancel={() => this.switchAlert()}
        onSuccess={this.remove}
        successText="Remove"
        isVisible={isOpenAlert}
      >
        <H4 style={{ textAlign: 'center' }}>
          {`Are you sure that can remove the "${
            locationSelected ? locationSelected.identificator : ''
          }" location`}
        </H4>
      </AlertConfirm>
    );
  };

  render() {
    const {
      list,
      pages,
      currentPage,
      loading,
      states,
      brands,
      accounts,
      fetchLocations,
      role_code,
    } = this.props;
    const { search, brand_id, account_id, state, installed } = this.state;

    return (
      <Container>
        <Header>
          <SearchContainer>
            <InputSearch onChange={this.searchOnChange} value={search} name="search" />
          </SearchContainer>
          {['admin', 'general'].includes(role_code) && (
            <ButtonContainer>
              <ButtonGroup>
                <ButtonText
                  theme="primary"
                  text="New"
                  icon="plus"
                  onClick={() => push('/locations/create')}
                />
              </ButtonGroup>
            </ButtonContainer>
          )}
        </Header>

        <FilterGroup>
          <FilterContainer>
            <Filter
              title="Brand:"
              selectedValue={brand_id}
              name="brand_id"
              handleChange={this.filterOnChange}
              options={brands}
            />
          </FilterContainer>
          <FilterContainer>
            <Filter
              title="Account:"
              selectedValue={account_id}
              name="account_id"
              handleChange={this.filterOnChange}
              options={accounts}
            />
          </FilterContainer>
          <FilterContainer>
            <Filter
              title="State:"
              selectedValue={state}
              name="state"
              handleChange={this.filterOnChange}
              options={states}
            />
          </FilterContainer>
          <FilterContainer>
            <Filter
              title="Installation:"
              selectedValue={installed}
              name="installed"
              handleChange={this.filterOnChange}
              options={[
                { value: '', label: 'All' },
                { value: true, label: 'Installed' },
                { value: false, label: 'Not Installed' },
              ]}
            />
          </FilterContainer>
        </FilterGroup>
        <TableContainer>
          <Table>
            <THeader>
              <TableTitle width="19%">Location ID</TableTitle>
              <TableTitle width="19%">Brand</TableTitle>
              <TableTitle width="19%">Account</TableTitle>
              <TableTitle width="14%">Address</TableTitle>
              <TableTitle width="14%">Contact</TableTitle>
              <TableTitle width="10%">Status</TableTitle>
              <TableTitle width="5%" />
            </THeader>
            <TBody>
              {loading ? (
                <Loader size={100} block />
              ) : (
                list.map((item, key) => this.renderItem(item, key))
              )}
            </TBody>
          </Table>
          {(!list || !list.length) && !loading && (
            <ListEmpty message="There are currently no locations" />
          )}
        </TableContainer>
        <Footer>
          <PaginationButtons
            currentPage={currentPage}
            changePage={page =>
              fetchLocations({ page, search, brand_id, account_id, state, installed })
            }
            totalPages={pages}
          />
        </Footer>
        {this.renderAlertConfirm()}
      </Container>
    );
  }
}

LocationListView.propTypes = {
  accounts: PropTypes.array,
  brands: PropTypes.array,
  currentPage: PropTypes.number,
  fetchAccounts: PropTypes.func,
  fetchLocations: PropTypes.func,
  list: PropTypes.array,
  loading: PropTypes.bool,
  pages: PropTypes.number,
  removeLocation: PropTypes.func,
  role_code: PropTypes.string,
  states: PropTypes.array,
};
