// @flow
/**
 * @module Actions/Alert
 * @desc Alert Actions
 */
import { createActions } from 'redux-actions';

import { ActionTypes } from 'constants/index';

export const {
  getLocations,
  getLocation,
  getLocationsForFilter,
  createLocation,
  editLocation,
  deleteLocation,
  changeMembranes,
  getCsvFiles,
  setDeviceId,
  updateInstallationStatus,
  // water filter dynamo
  getWaterFilterCurrentState,
  getWaterFilterCurrentPressure,
  getWaterFilterConsumption,
  getWaterFilterMaxFlowRate,
  getWaterFilterPressureForConsumption,
  getWaterFilterPressureForFlowRate,
  getWaterFilterBypass,
  getTotalConsumptionWaterFilter,
  waterFilterSetInitialState,
  generateWaterFilterCsv,
  getWaterFilterCurrentBypass,
  getTotalConsumptionCartridgeWf,
  getAllTotalConsumptionCartridgeWf,
  replaceWaterFilterCartridges,
  // reverse osmosis dynamo
  getReverseOsmosisCurrentState,
  getReverseOsmosisConsumption,
  getTotalConsumptionReverseOsmosis,
  getReverseOsmosisTds,
  getReverseOsmosisWaste,
  reverseOsmosisSetInitialState,
  generateReverseOsmosisCsv,
  getTotalConsumptionRo,
  getTotalConsumptionCartridgeRo,
  getAllTotalConsumptionCartridgeRo,
  replaceReverseOsmosisCartridges,
  getReverseOsmosisBypass,
  getReverseOsmosisCurrentBypass,
  getReverseOsmosisCurrentPressure,
  getReverseOsmosisCurrentTemperature,
  // softener dynamo
  getSoftenerCurrentState,
  getSoftenerConsumption,
  getSoftenerPressure,
  getSoftenerBypass,
  getSoftenerWaste,
  softenerSetInitialState,
  generateSoftenerCsv,
  getConsumptionFromInstallSo,
  getSoftenerCurrentPressure,
  getSoftenerCurrentBypass,
} = createActions({
  [ActionTypes.GET_LOCATIONS]: params => params,
  [ActionTypes.GET_LOCATION]: id => ({ id }),
  [ActionTypes.GET_LOCATIONS_FOR_FILTER]: params => params,
  [ActionTypes.CREATE_LOCATION]: data => data,
  [ActionTypes.EDIT_LOCATION]: (id, location) => ({ id, location }),
  [ActionTypes.DELETE_LOCATION]: id => ({ id }),
  [ActionTypes.CHANGE_MEMBRANES]: data => data,
  [ActionTypes.GET_CSV_FILES]: params => params,
  [ActionTypes.SET_DEVICE_ID]: params => params,
  [ActionTypes.UPDATE_INSTALLATION_STATUS]: (id, location) => ({ id, location }),

  // water filter dynamo
  [ActionTypes.GET_WATER_FILTER_CURRENT_STATE]: serialNumber => serialNumber,
  [ActionTypes.GET_WATER_FILTER_CURRENT_PRESSURE]: serialNumber => serialNumber,
  [ActionTypes.GET_WATER_FILTER_CONSUMPTION]: params => params,
  [ActionTypes.GET_WATER_FILTER_MAX_FLOW_RATE]: params => params,
  [ActionTypes.GET_WATER_FILTER_PRESSURE_FOR_FLOW_RATE]: params => params,
  [ActionTypes.GET_WATER_FILTER_PRESSURE_FOR_CONSUMPTION]: params => params,
  [ActionTypes.GET_WATER_FILTER_BYPASS]: params => params,
  [ActionTypes.GET_TOTAL_CONSUMPTION_WATER_FILTER]: params => params,
  [ActionTypes.WATER_FILTER_SET_INITIAL_STATE]: () => ({}),
  [ActionTypes.GENERATE_WATER_FILTER_CSV]: params => params,
  [ActionTypes.GET_WATER_FILTER_CURRENT_BYPASS]: serialNumber => serialNumber,
  [ActionTypes.GET_TOTAL_CONSUMPTION_CARTRIDGE_WF]: params => params,
  [ActionTypes.GET_ALL_TOTAL_CONSUMPTION_CARTRIDGE_WF]: params => params,
  [ActionTypes.REPLACE_WATER_FILTER_CARTRIDGES]: params => params,

  // reverse osmosis dynamo
  [ActionTypes.GET_REVERSE_OSMOSIS_CURRENT_STATE]: serialNumber => serialNumber,
  [ActionTypes.GET_REVERSE_OSMOSIS_CONSUMPTION]: params => params,
  [ActionTypes.GET_TOTAL_CONSUMPTION_REVERSE_OSMOSIS]: params => params,
  [ActionTypes.GET_REVERSE_OSMOSIS_TDS]: params => params,
  [ActionTypes.GET_REVERSE_OSMOSIS_WASTE]: params => params,
  [ActionTypes.REVERSE_OSMOSIS_SET_INITIAL_STATE]: () => ({}),
  [ActionTypes.GENERATE_REVERSE_OSMOSIS_CSV]: params => params,
  [ActionTypes.GET_TOTAL_CONSUMPTION_RO]: params => params,
  [ActionTypes.GET_TOTAL_CONSUMPTION_CARTRIDGE_RO]: params => params,
  [ActionTypes.GET_ALL_TOTAL_CONSUMPTION_CARTRIDGE_RO]: params => params,
  [ActionTypes.REPLACE_REVERSE_OSMOSIS_CARTRIDGES]: params => params,
  [ActionTypes.GET_REVERSE_OSMOSIS_BYPASS]: params => params,
  [ActionTypes.GET_REVERSE_OSMOSIS_CURRENT_BYPASS]: serialNumber => serialNumber,
  [ActionTypes.GET_REVERSE_OSMOSIS_CURRENT_PRESSURE]: params => params,
  [ActionTypes.GET_REVERSE_OSMOSIS_CURRENT_TEMPERATURE]: params => params,

  // softener dynamo
  [ActionTypes.GET_SOFTENER_CURRENT_STATE]: params => params,
  [ActionTypes.GET_SOFTENER_CONSUMPTION]: params => params,
  [ActionTypes.GET_SOFTENER_PRESSURE]: params => params,
  [ActionTypes.GET_SOFTENER_BYPASS]: params => params,
  [ActionTypes.GET_SOFTENER_WASTE]: params => params,
  [ActionTypes.SOFTENER_SET_INITIAL_STATE]: () => ({}),
  [ActionTypes.GENERATE_SOFTENER_CSV]: params => params,
  [ActionTypes.GET_CONSUMPTION_FROM_INSTALL_SO]: params => params,
  [ActionTypes.GET_SOFTENER_CURRENT_PRESSURE]: serialNumber => serialNumber,
  [ActionTypes.GET_SOFTENER_CURRENT_BYPASS]: serialNumber => serialNumber,
});
