import { keyMirror } from 'modules/helpers';
import { Colors } from 'modules/theme';

/**
 * @namespace Constants
 * @desc App constants
 */

/**
 * @constant {Object} ActionTypes
 * @memberof Constants
 */
export const ActionTypes = keyMirror({
  STARTUP: undefined,
  EXCEPTION: undefined,
  USER_LOGIN: undefined,
  USER_LOGIN_SUCCESS: undefined,
  USER_LOGIN_FAILURE: undefined,

  USER_LOGOUT: undefined,
  USER_LOGOUT_SUCCESS: undefined,
  USER_LOGOUT_FAILURE: undefined,
  SHOW_ALERT: undefined,
  HIDE_ALERT: undefined,
  RECOVER_PASSWORD: undefined,
  RESET_PASSWORD: undefined,
  NEW_PASSWORD: undefined,
  UPDATE_PROFILE: undefined,
  UPDATE_PROFILE_SUCCESS: undefined,
  REQUIRE_LOGOUT: undefined,
  CHANGE_PASSWORD: undefined,
  CLOSE_ACCOUNT: undefined,
  UPDATE_INVITATION_TOKEN_EXPIRED: undefined,
  RESEND_INVITATION: undefined,
  // yp
  GET_LIBRARIES: undefined,
  GET_LIBRARIES_SUCCESS: undefined,
  GET_LIBRARIES_FAILURE: undefined,
  DELETE_LIBRARY: undefined,
  DELETE_LIBRARY_SUCCESS: undefined,
  DELETE_LIBRARY_FAILURE: undefined,
  // library - water catridge
  GET_CATRIDGE: undefined,
  GET_CATRIDGE_SUCCESS: undefined,
  GET_CATRIDGE_FAILURE: undefined,
  GET_CATRIDGES_FOR_FILTER: undefined,
  GET_CATRIDGES_FOR_FILTER_SUCCESS: undefined,
  GET_CATRIDGES_FOR_FILTER_FAILURE: undefined,
  CREATE_WATER_CATRIDGE: undefined,
  CREATE_WATER_CATRIDGE_SUCCESS: undefined,
  CREATE_WATER_CATRIDGE_FAILURE: undefined,
  EDIT_WATER_CATRIDGE: undefined,
  EDIT_WATER_CATRIDGE_SUCCESS: undefined,
  EDIT_WATER_CATRIDGE_FAILURE: undefined,
  // library - water filter
  GET_WATER_FILTER: undefined,
  GET_WATER_FILTER_SUCCESS: undefined,
  GET_WATER_FILTER_FAILURE: undefined,
  GET_WATER_FILTERS_FOR_FILTER: undefined,
  GET_WATER_FILTERS_FOR_FILTER_SUCCESS: undefined,
  GET_WATER_FILTERS_FOR_FILTER_FAILURE: undefined,
  CREATE_WATER_FILTER: undefined,
  CREATE_WATER_FILTER_SUCCESS: undefined,
  CREATE_WATER_FILTER_FAILURE: undefined,
  EDIT_WATER_FILTER: undefined,
  EDIT_WATER_FILTER_SUCCESS: undefined,
  EDIT_WATER_FILTER_FAILURE: undefined,
  GET_WATER_FILTER_PRESSURE_FOR_FLOW_RATE: undefined,
  GET_WATER_FILTER_PRESSURE_FOR_FLOW_RATE_SUCCESS: undefined,
  GET_WATER_FILTER_PRESSURE_FOR_FLOW_RATE_FAILURE: undefined,
  GET_WATER_FILTER_PRESSURE_FOR_CONSUMPTION: undefined,
  GET_WATER_FILTER_PRESSURE_FOR_CONSUMPTION_SUCCESS: undefined,
  GET_WATER_FILTER_PRESSURE_FOR_CONSUMPTION_FAILURE: undefined,
  // For filter
  GET_REVERSE_OSMOSIS_MEMBRANE_FOR_FILTER: undefined,
  GET_REVERSE_OSMOSIS_MEMBRANE_FOR_FILTER_SUCCESS: undefined,
  GET_REVERSE_OSMOSIS_MEMBRANE_FOR_FILTER_FAILURE: undefined,
  GET_REVERSE_OSMOSIS_FOR_FILTER: undefined,
  GET_REVERSE_OSMOSIS_FOR_FILTER_SUCCESS: undefined,
  GET_REVERSE_OSMOSIS_FOR_FILTER_FAILURE: undefined,
  GET_SOFTENER_FOR_FILTER: undefined,
  GET_SOFTENER_FOR_FILTER_SUCCESS: undefined,
  GET_SOFTENER_FOR_FILTER_FAILURE: undefined,
  GET_BRINE_DRUM_FOR_FILTER: undefined,
  GET_BRINE_DRUM_FOR_FILTER_SUCCESS: undefined,
  GET_BRINE_DRUM_FOR_FILTER_FAILURE: undefined,
  // library - configuration
  GET_CONFIGURATION: undefined,
  GET_CONFIGURATION_SUCCESS: undefined,
  GET_CONFIGURATION_FAILURE: undefined,
  GET_WATER_FILTER_CONFIGURATIONS_FOR_FILTER: undefined,
  GET_WATER_FILTER_CONFIGURATIONS_FOR_FILTER_SUCCESS: undefined,
  GET_WATER_FILTER_CONFIGURATIONS_FOR_FILTER_FAILURE: undefined,
  GET_REVERSE_OSMOSIS_CONFIGURATIONS_FOR_FILTER: undefined,
  GET_REVERSE_OSMOSIS_CONFIGURATIONS_FOR_FILTER_SUCCESS: undefined,
  GET_REVERSE_OSMOSIS_CONFIGURATIONS_FOR_FILTER_FAILURE: undefined,
  GET_SOFTENER_CONFIGURATIONS_FOR_FILTER: undefined,
  GET_SOFTENER_CONFIGURATIONS_FOR_FILTER_SUCCESS: undefined,
  GET_SOFTENER_CONFIGURATIONS_FOR_FILTER_FAILURE: undefined,

  CREATE_CONFIGURATION: undefined,
  CREATE_CONFIGURATION_SUCCESS: undefined,
  CREATE_CONFIGURATION_FAILURE: undefined,
  EDIT_CONFIGURATION: undefined,
  EDIT_CONFIGURATION_SUCCESS: undefined,
  EDIT_CONFIGURATION_FAILURE: undefined,
  // library - create softener
  CREATE_SOFTENER: undefined,
  CREATE_SOFTENER_SUCCESS: undefined,
  CREATE_SOFTENER_FAILURE: undefined,
  // library - create reverse osmosis
  CREATE_REVERSE_OSMOSIS: undefined,
  CREATE_REVERSE_OSMOSIS_SUCCESS: undefined,
  CREATE_REVERSE_OSMOSIS_FAILURE: undefined,
  // library - create brine drum
  CREATE_BRINE_DRUM: undefined,
  CREATE_BRINE_DRUM_SUCCESS: undefined,
  CREATE_BRINE_DRUM_FAILURE: undefined,
  // library - create reverse osmosis membrane
  CREATE_REVERSE_OSMOSIS_MEMBRANE: undefined,
  CREATE_REVERSE_OSMOSIS_MEMBRANE_SUCCESS: undefined,
  CREATE_REVERSE_OSMOSIS_MEMBRANE_FAILURE: undefined,
  // library - edit softener
  EDIT_SOFTENER: undefined,
  EDIT_SOFTENER_SUCCESS: undefined,
  EDIT_SOFTENER_FAILURE: undefined,
  // library - edit reverse osmosis
  EDIT_REVERSE_OSMOSIS: undefined,
  EDIT_REVERSE_OSMOSIS_SUCCESS: undefined,
  EDIT_REVERSE_OSMOSIS_FAILURE: undefined,
  // library - edit brine drum
  EDIT_BRINE_DRUM: undefined,
  EDIT_BRINE_DRUM_SUCCESS: undefined,
  EDIT_BRINE_DRUM_FAILURE: undefined,
  // library - edit reverse osmosis membrane
  EDIT_REVERSE_OSMOSIS_MEMBRANE: undefined,
  EDIT_REVERSE_OSMOSIS_MEMBRANE_SUCCESS: undefined,
  EDIT_REVERSE_OSMOSIS_MEMBRANE_FAILURE: undefined,
  // library - get reverse osmosis membrane
  GET_REVERSE_OSMOSIS_MEMBRANE: undefined,
  GET_REVERSE_OSMOSIS_MEMBRANE_SUCCESS: undefined,
  GET_REVERSE_OSMOSIS_MEMBRANE_FAILURE: undefined,
  // library - get reverse osmosis
  GET_REVERSE_OSMOSIS: undefined,
  GET_REVERSE_OSMOSIS_SUCCESS: undefined,
  GET_REVERSE_OSMOSIS_FAILURE: undefined,
  // library - get softener
  GET_SOFTENER: undefined,
  GET_SOFTENER_SUCCESS: undefined,
  GET_SOFTENER_FAILURE: undefined,
  // library - get brine drum
  GET_BRINE_DRUM: undefined,
  GET_BRINE_DRUM_SUCCESS: undefined,
  GET_BRINE_DRUM_FAILURE: undefined,
  // library - get tank
  GET_TANKS: undefined,
  GET_TANKS_SUCCESS: undefined,
  GET_TANKS_FAILURE: undefined,
  // library - create sensors
  CREATE_SENSOR: undefined,
  CREATE_SENSOR_SUCCESS: undefined,
  CREATE_SENSOR_FAILURE: undefined,
  // library - edit sensors
  EDIT_SENSOR: undefined,
  EDIT_SENSOR_SUCCESS: undefined,
  EDIT_SENSOR_FAILURE: undefined,
  // library - get sensors
  GET_SENSOR: undefined,
  GET_SENSOR_SUCCESS: undefined,
  GET_SENSOR_FAILURE: undefined,
  // library - flow sensor
  GET_FLOW_SENSORS_FOR_SELECT: undefined,
  GET_FLOW_SENSORS_FOR_SELECT_SUCCESS: undefined,
  GET_FLOW_SENSORS_FOR_SELECT_FAILURE: undefined,
  // library - pressure sensor
  GET_PRESSURE_SENSORS_FOR_SELECT: undefined,
  GET_PRESSURE_SENSORS_FOR_SELECT_SUCCESS: undefined,
  GET_PRESSURE_SENSORS_FOR_SELECT_FAILURE: undefined,
  // library - distance sensor
  GET_DISTANCE_SENSORS_FOR_SELECT: undefined,
  GET_DISTANCE_SENSORS_FOR_SELECT_SUCCESS: undefined,
  GET_DISTANCE_SENSORS_FOR_SELECT_FAILURE: undefined,
  // library - tds probe sensor
  GET_TDS_PROBE_SENSORS_FOR_SELECT: undefined,
  GET_TDS_PROBE_SENSORS_FOR_SELECT_SUCCESS: undefined,
  GET_TDS_PROBE_SENSORS_FOR_SELECT_FAILURE: undefined,
  // library - temp probe sensor
  GET_TEMPERATURE_SENSORS_FOR_SELECT: undefined,
  GET_TEMPERATURE_SENSORS_FOR_SELECT_SUCCESS: undefined,
  GET_TEMPERATURE_SENSORS_FOR_SELECT_FAILURE: undefined,

  // location
  GET_LOCATIONS: undefined,
  GET_LOCATIONS_SUCCESS: undefined,
  GET_LOCATIONS_FAILURE: undefined,
  GET_LOCATION: undefined,
  GET_LOCATION_SUCCESS: undefined,
  GET_LOCATION_FAILURE: undefined,
  GET_LOCATIONS_FOR_FILTER: undefined,
  GET_LOCATIONS_FOR_FILTER_SUCCESS: undefined,
  GET_LOCATIONS_FOR_FILTER_FAILURE: undefined,
  EDIT_LOCATION: undefined,
  EDIT_LOCATION_RESOLVED: undefined,
  DELETE_LOCATION: undefined,
  DELETE_LOCATION_SUCCESS: undefined,
  DELETE_LOCATION_FAILURE: undefined,
  CREATE_LOCATION: undefined,
  CREATE_LOCATION_RESOLVED: undefined,
  CHANGE_MEMBRANES: undefined,
  GET_CSV_FILES: undefined,
  GET_CSV_FILES_FAILURE: undefined,
  GET_CSV_FILES_SUCCESS: undefined,
  SET_DEVICE_ID: undefined,
  SET_DEVICE_ID_SUCCESS: undefined,
  SET_DEVICE_ID_FAILURE: undefined,
  UPDATE_INSTALLATION_STATUS: undefined,
  UPDATE_INSTALLATION_STATUS_SUCCESS: undefined,
  UPDATE_INSTALLATION_STATUS_FAILURE: undefined,

  // water filter dynamo
  GET_WATER_FILTER_CURRENT_STATE: undefined,
  GET_WATER_FILTER_CURRENT_STATE_SUCCESS: undefined,
  GET_WATER_FILTER_CURRENT_STATE_FAILURE: undefined,
  GET_WATER_FILTER_CURRENT_PRESSURE: undefined,
  GET_WATER_FILTER_CURRENT_PRESSURE_SUCCESS: undefined,
  GET_WATER_FILTER_CURRENT_PRESSURE_FAILURE: undefined,
  GET_WATER_FILTER_CONSUMPTION: undefined,
  GET_WATER_FILTER_CONSUMPTION_SUCCESS: undefined,
  GET_WATER_FILTER_CONSUMPTION_FAILURE: undefined,
  GET_WATER_FILTER_MAX_FLOW_RATE: undefined,
  GET_WATER_FILTER_MAX_FLOW_RATE_SUCCESS: undefined,
  GET_WATER_FILTER_MAX_FLOW_RATE_FAILURE: undefined,
  GET_TOTAL_CONSUMPTION_WATER_FILTER: undefined,
  GET_TOTAL_CONSUMPTION_WATER_FILTER_SUCCESS: undefined,
  GET_TOTAL_CONSUMPTION_WATER_FILTER_FAILURE: undefined,
  GET_WATER_FILTER_BYPASS: undefined,
  GET_WATER_FILTER_BYPASS_SUCCESS: undefined,
  GET_WATER_FILTER_BYPASS_FAILURE: undefined,
  WATER_FILTER_SET_INITIAL_STATE: undefined,
  GENERATE_WATER_FILTER_CSV: undefined,
  GENERATE_WATER_FILTER_CSV_SUCCESS: undefined,
  GENERATE_WATER_FILTER_CSV_FAILURE: undefined,
  GET_WATER_FILTER_CURRENT_BYPASS: undefined,
  GET_WATER_FILTER_CURRENT_BYPASS_SUCCESS: undefined,
  GET_WATER_FILTER_CURRENT_BYPASS_FAILURE: undefined,
  GET_TOTAL_CONSUMPTION_CARTRIDGE_WF: undefined,
  GET_TOTAL_CONSUMPTION_CARTRIDGE_WF_SUCCESS: undefined,
  GET_TOTAL_CONSUMPTION_CARTRIDGE_WF_FAILURE: undefined,
  GET_ALL_TOTAL_CONSUMPTION_CARTRIDGE_WF: undefined,
  GET_ALL_TOTAL_CONSUMPTION_CARTRIDGE_WF_SUCCESS: undefined,
  GET_ALL_TOTAL_CONSUMPTION_CARTRIDGE_WF_FAILURE: undefined,
  REPLACE_WATER_FILTER_CARTRIDGES: undefined,
  REPLACE_WATER_FILTER_CARTRIDGES_SUCCESS: undefined,
  REPLACE_WATER_FILTER_CARTRIDGES_FAILURE: undefined,
  WF_FOR_PDF: undefined,
  // reverse osmosis dynamo
  GET_REVERSE_OSMOSIS_CURRENT_STATE: undefined,
  GET_REVERSE_OSMOSIS_CURRENT_STATE_SUCCESS: undefined,
  GET_REVERSE_OSMOSIS_CURRENT_STATE_FAILURE: undefined,
  GET_REVERSE_OSMOSIS_CONSUMPTION: undefined,
  GET_REVERSE_OSMOSIS_CONSUMPTION_SUCCESS: undefined,
  GET_REVERSE_OSMOSIS_CONSUMPTION_FAILURE: undefined,
  GET_TOTAL_CONSUMPTION_REVERSE_OSMOSIS: undefined,
  GET_TOTAL_CONSUMPTION_REVERSE_OSMOSIS_SUCCESS: undefined,
  GET_TOTAL_CONSUMPTION_REVERSE_OSMOSIS_FAILURE: undefined,
  GET_REVERSE_OSMOSIS_TDS: undefined,
  GET_REVERSE_OSMOSIS_TDS_SUCCESS: undefined,
  GET_REVERSE_OSMOSIS_TDS_FAILURE: undefined,
  GET_REVERSE_OSMOSIS_WASTE: undefined,
  GET_REVERSE_OSMOSIS_WASTE_SUCCESS: undefined,
  GET_REVERSE_OSMOSIS_WASTE_FAILURE: undefined,
  REVERSE_OSMOSIS_SET_INITIAL_STATE: undefined,
  GENERATE_REVERSE_OSMOSIS_CSV: undefined,
  GENERATE_REVERSE_OSMOSIS_CSV_SUCCESS: undefined,
  GENERATE_REVERSE_OSMOSIS_CSV_FAILURE: undefined,
  GET_TOTAL_CONSUMPTION_RO: undefined,
  GET_TOTAL_CONSUMPTION_RO_SUCCESS: undefined,
  GET_TOTAL_CONSUMPTION_RO_FAILURE: undefined,
  GET_TOTAL_CONSUMPTION_CARTRIDGE_RO: undefined,
  GET_TOTAL_CONSUMPTION_CARTRIDGE_RO_SUCCESS: undefined,
  GET_TOTAL_CONSUMPTION_CARTRIDGE_RO_FAILURE: undefined,
  GET_ALL_TOTAL_CONSUMPTION_CARTRIDGE_RO: undefined,
  GET_ALL_TOTAL_CONSUMPTION_CARTRIDGE_RO_SUCCESS: undefined,
  GET_ALL_TOTAL_CONSUMPTION_CARTRIDGE_RO_FAILURE: undefined,
  REPLACE_REVERSE_OSMOSIS_CARTRIDGES: undefined,
  REPLACE_REVERSE_OSMOSIS_CARTRIDGES_SUCCESS: undefined,
  REPLACE_REVERSE_OSMOSIS_CARTRIDGES_FAILURE: undefined,
  RO_FOR_PDF: undefined,
  GET_REVERSE_OSMOSIS_BYPASS: undefined,
  GET_REVERSE_OSMOSIS_BYPASS_SUCCESS: undefined,
  GET_REVERSE_OSMOSIS_BYPASS_FAILURE: undefined,
  GET_REVERSE_OSMOSIS_CURRENT_BYPASS: undefined,
  GET_REVERSE_OSMOSIS_CURRENT_BYPASS_SUCCESS: undefined,
  GET_REVERSE_OSMOSIS_CURRENT_BYPASS_FAILURE: undefined,
  GET_REVERSE_OSMOSIS_CURRENT_PRESSURE: undefined,
  GET_REVERSE_OSMOSIS_CURRENT_PRESSURE_SUCCESS: undefined,
  GET_REVERSE_OSMOSIS_CURRENT_PRESSURE_FAILURE: undefined,
  GET_REVERSE_OSMOSIS_CURRENT_TEMPERATURE: undefined,
  GET_REVERSE_OSMOSIS_CURRENT_TEMPERATURE_SUCCESS: undefined,
  GET_REVERSE_OSMOSIS_CURRENT_TEMPERATURE_FAILURE: undefined,
  // softener dynamo
  GET_SOFTENER_CURRENT_STATE: undefined,
  GET_SOFTENER_CURRENT_STATE_SUCCESS: undefined,
  GET_SOFTENER_CURRENT_STATE_FAILURE: undefined,
  GET_SOFTENER_CONSUMPTION: undefined,
  GET_SOFTENER_CONSUMPTION_SUCCESS: undefined,
  GET_SOFTENER_CONSUMPTION_FAILURE: undefined,
  GET_SOFTENER_BYPASS: undefined,
  GET_SOFTENER_BYPASS_SUCCESS: undefined,
  GET_SOFTENER_BYPASS_FAILURE: undefined,
  GET_SOFTENER_WASTE: undefined,
  GET_SOFTENER_WASTE_SUCCESS: undefined,
  GET_SOFTENER_WASTE_FAILURE: undefined,
  SOFTENER_SET_INITIAL_STATE: undefined,
  GENERATE_SOFTENER_CSV: undefined,
  GENERATE_SOFTENER_CSV_SUCCESS: undefined,
  GENERATE_SOFTENER_CSV_FAILURE: undefined,
  GET_CONSUMPTION_FROM_INSTALL_SO: undefined,
  GET_CONSUMPTION_FROM_INSTALL_SO_SUCCESS: undefined,
  GET_CONSUMPTION_FROM_INSTALL_SO_FAILURE: undefined,
  GET_SOFTENER_CURRENT_PRESSURE: undefined,
  GET_SOFTENER_CURRENT_PRESSURE_SUCCESS: undefined,
  GET_SOFTENER_CURRENT_PRESSURE_FAILURE: undefined,
  GET_SOFTENER_PRESSURE: undefined,
  GET_SOFTENER_PRESSURE_SUCCESS: undefined,
  GET_SOFTENER_PRESSURE_FAILURE: undefined,
  GET_SOFTENER_CURRENT_BYPASS: undefined,
  GET_SOFTENER_CURRENT_BYPASS_SUCCESS: undefined,
  GET_SOFTENER_CURRENT_BYPASS_FAILURE: undefined,
  // alert
  GET_ALERTS: undefined,
  GET_ALERTS_SUCCESS: undefined,
  GET_ALERTS_FAILURE: undefined,
  GET_ALL_ALERTS: undefined,
  GET_ALL_ALERTS_SUCCESS: undefined,
  GET_ALL_ALERTS_FAILURE: undefined,
  GET_ALERT: undefined,
  GET_ALERT_SUCCESS: undefined,
  GET_ALERT_FAILURE: undefined,
  GET_ALERTS_TYPES: undefined,
  GET_ALERTS_TYPES_SUCCESS: undefined,
  GET_ALERTS_TYPES_FAILURE: undefined,
  GET_ALERT_TYPE: undefined,
  GET_ALERT_TYPE_SUCCESS: undefined,
  GET_ALERT_TYPE_FAILURE: undefined,
  CREATE_ALERT_TYPE: undefined,
  CREATE_ALERT_TYPE_RESOLVED: undefined,
  EDIT_ALERT_TYPE: undefined,
  EDIT_ALERT_TYPE_RESOLVED: undefined,
  DELETE_ALERT_TYPE: undefined,
  DELETE_ALERT_TYPE_SUCCESS: undefined,
  DELETE_ALERT_TYPE_FAILURE: undefined,
  MARK_ALERT_AS_VIEWED: undefined,
  MARK_ALERT_AS_VIEWED_FAILURE: undefined,
  MARK_ALERT_AS_VIEWED_SUCCESS: undefined,

  // user
  GET_USERS: undefined,
  GET_USERS_SUCCESS: undefined,
  GET_USERS_FAILURE: undefined,
  GET_USER: undefined,
  GET_USER_SUCCESS: undefined,
  GET_USER_FAILURE: undefined,
  RESEND_EMAIL: undefined,
  RESEND_EMAIL_SUCCESS: undefined,
  RESEND_EMAIL_FAILURE: undefined,
  CREATE_USER: undefined,
  CREATE_USER_RESOLVED: undefined,
  EDIT_USER: undefined,
  EDIT_USER_RESOLVED: undefined,
  GET_INSTALLERS_BY_ACCOUNT: undefined,
  GET_INSTALLERS_BY_ACCOUNT_SUCCESS: undefined,
  GET_INSTALLERS_BY_ACCOUNT_FAILURE: undefined,
  DELETE_USER: undefined,
  DELETE_USER_SUCCESS: undefined,
  DELETE_USER_FAILURE: undefined,
  // account
  GET_ACCOUNTS: undefined,
  GET_ACCOUNTS_SUCCESS: undefined,
  GET_ACCOUNTS_FAILURE: undefined,
  GET_ACCOUNT: undefined,
  GET_ACCOUNT_SUCCESS: undefined,
  GET_ACCOUNT_FAILURE: undefined,
  GET_ACCOUNTS_FOR_FILTER: undefined,
  GET_ACCOUNTS_FOR_FILTER_SUCCESS: undefined,
  GET_ACCOUNTS_FOR_FILTER_FAILURE: undefined,
  CREATE_ACCOUNT: undefined,
  CREATE_ACCOUNT_RESOLVED: undefined,
  EDIT_ACCOUNT: undefined,
  EDIT_ACCOUNT_RESOLVED: undefined,
  DELETE_ACCOUNT: undefined,
  DELETE_ACCOUNT_SUCCESS: undefined,
  DELETE_ACCOUNT_FAILURE: undefined,
  GET_ADMINS_ACCOUNTS: undefined,
  GET_ADMINS_ACCOUNTS_SUCCESS: undefined,
  GET_ADMINS_ACCOUNTS_FAILURE: undefined,
  GET_ADMINS_LOCATIONS: undefined,
  GET_ADMINS_LOCATIONS_SUCCESS: undefined,
  GET_ADMINS_LOCATIONS_FAILURE: undefined,
  // brand
  GET_BRANDS: undefined,
  GET_BRANDS_SUCCESS: undefined,
  GET_BRANDS_FAILURE: undefined,
  GET_BRAND: undefined,
  GET_BRAND_SUCCESS: undefined,
  GET_BRAND_FAILURE: undefined,
  GET_BRANDS_FOR_FILTER: undefined,
  GET_BRANDS_FOR_FILTER_SUCCESS: undefined,
  GET_BRANDS_FOR_FILTER_FAILURE: undefined,
  CREATE_BRAND: undefined,
  CREATE_BRAND_RESOLVED: undefined,
  EDIT_BRAND: undefined,
  EDIT_BRAND_RESOLVED: undefined,
  DELETE_BRAND: undefined,
  DELETE_BRAND_SUCCESS: undefined,
  DELETE_BRAND_FAILURE: undefined,
  GET_ADMINS_BRANDS: undefined,
  GET_ADMINS_BRANDS_SUCCESS: undefined,
  GET_ADMINS_BRANDS_FAILURE: undefined,
  // utils
  GET_ROLES: undefined,
  GET_ROLES_SUCCESS: undefined,
  GET_ROLES_FAILURE: undefined,
  GET_STATES: undefined,
  GET_STATES_SUCCESS: undefined,
  GET_STATES_FAILURE: undefined,
  GET_CITIES_BY_STATE: undefined,
  GET_CITIES_BY_STATE_SUCCESS: undefined,
  GET_CITIES_BY_STATE_FAILURE: undefined,
  // supports
  GET_SUPPORTS: undefined,
  GET_SUPPORTS_SUCCESS: undefined,
  GET_SUPPORTS_FAILURE: undefined,
  CHECK_AS_READ: undefined,
  CHECK_AS_READ_SUCCESS: undefined,
  CHECK_AS_READ_FAILURE: undefined,
  DELETE_SUPPORT: undefined,
  DELETE_SUPPORT_SUCCESS: undefined,
  DELETE_SUPPORT_FAILURE: undefined,
  // ota
  GET_OTAS: undefined,
  GET_OTAS_SUCCESS: undefined,
  GET_OTAS_FAILURE: undefined,
  GET_OTA: undefined,
  GET_OTA_SUCCESS: undefined,
  GET_OTA_FAILURE: undefined,
  GET_OTA_VERSIONS_WF_AND_SOFTENER: undefined,
  GET_OTA_VERSIONS_WF_AND_SOFTENER_SUCCESS: undefined,
  GET_OTA_VERSIONS_WF_AND_SOFTENER_FAILURE: undefined,
  GET_OTA_VERSIONS_RO: undefined,
  GET_OTA_VERSIONS_RO_SUCCESS: undefined,
  GET_OTA_VERSIONS_RO_FAILURE: undefined,
  CREATE_OTA_VERSION: undefined,
  CREATE_OTA_VERSION_SUCCESS: undefined,
  CREATE_OTA_VERSION_FAILURE: undefined,
  DELETE_OTA_VERSION: undefined,
  DELETE_OTA_VERSION_SUCCESS: undefined,
  DELETE_OTA_VERSION_FAILURE: undefined,
  GET_UPGRADE_STATUS: undefined,
  GET_UPGRADE_STATUS_SUCCESS: undefined,
  GET_UPGRADE_STATUS_FAILURE: undefined,
  GET_UPGRADE_STATUS_DEVICES: undefined,
  GET_UPGRADE_STATUS_DEVICES_SUCCESS: undefined,
  GET_UPGRADE_STATUS_DEVICES_FAILURE: undefined,
  CREATE_UPGRADE_STATUS: undefined,
  CREATE_UPGRADE_STATUS_SUCCESS: undefined,
  CREATE_UPGRADE_STATUS_FAILURE: undefined,
  DELETE_UPGRADE_STATUS: undefined,
  DELETE_UPGRADE_STATUS_SUCCESS: undefined,
  DELETE_UPGRADE_STATUS_FAILURE: undefined,
  GET_PREVIOUS_INSTALLATION_INFO: undefined,
  GET_PREVIOUS_INSTALLATION_INFO_SUCCESS: undefined,
  GET_PREVIOUS_INSTALLATION_INFO_FAILURE: undefined,
  GET_UPDATE_ACTIVE_DEVICES: undefined,
  GET_UPDATE_ACTIVE_DEVICES_SUCCESS: undefined,
  GET_UPDATE_ACTIVE_DEVICES_FAILURE: undefined,
});

/**
 * @constant {Object} InstallationStatus
 * @memberof Constants
 */
export const InstallationStatus = [
  { label: 'Complete', value: true },
  { label: 'Incomplete', value: false },
];

/**
 * @constant {Object} DeviceTypes
 * @memberof Constants
 */
export const DeviceTypes = [
  { label: 'Water filter', value: 'water-filter' },
  { label: 'Reverse Osmosis', value: 'reverse-osmosis' },
  { label: 'Softener', value: 'water-softener' },
];

/**
 * @constant {Object} OptionsBrands
 * @memberof Constants
 */
export const OptionsBrands = [{ label: 'Testing brand', value: 'testing-brand' }];

/**
 * @constant {Object} SensorTypes
 * @memberof Constants
 */
export const SensorTypes = [
  { label: 'Flow Meter', value: 'flow-meter' },
  { label: 'Pressure transducer', value: 'pressure-transducer' },
  { label: 'TDS probe', value: 'tds-probe' },
  { label: 'Temperature sensor', value: 'temperature' },
  { label: 'Distance sensor', value: 'distance' },
];

/**
 * @constant {Object} LibraryConfigTypes
 * @memberof Constants
 */
export const LibraryConfigTypes = [
  { label: 'Device Configuration / Water Filter', value: 'water-filter-configuration' },
  { label: 'Device Configuration / Reverse Osmosis', value: 'reverse-osmosis-configuration' },
  { label: 'Device Configuration / Water Softener', value: 'water-softener-configuration' },
];

/**
 * @constant {Object} LibrarySensorTypes
 * @memberof Constants
 */
export const LibrarySensorTypes = [
  { label: 'Sensor / Flow Meter', value: 'flow-meter-sensor' },
  { label: 'Sensor / Pressure Transducer', value: 'pressure-transducer-sensor' },
  { label: 'Sensor / Tds Probe', value: 'tds-probe-sensor' },
  { label: 'Sensor / Temperature', value: 'temperature-sensor' },
  { label: 'Sensor / Distance', value: 'distance-sensor' },
];

/**
 * @constant {Object} LibraryTypes
 * @memberof Constants
 */
export const LibraryTypes = [
  { label: 'Water Filter', value: 'water-filter' },
  { label: 'Water Softener', value: 'water-softener' },
  { label: 'Water Cartridge', value: 'water-catridge' },
  { label: 'Brine Drum', value: 'brine-drum' },
  { label: 'Reverse Osmosis', value: 'reverse-osmosis' },
  { label: 'RO Membrane', value: 'reverse-osmosis-membrane' },
  ...LibraryConfigTypes,
  ...LibrarySensorTypes,
];

/**
 * @constant {Object} AlertTypes
 * @memberof Constants
 */
export const AlertTypes = [
  { label: 'Success', value: 'success', icon: 'check-circle', iconColor: Colors.green },
  { label: 'Alert', value: 'alert', icon: 'exclamation-circle', iconColor: Colors.orange },
  { label: 'Warning', value: 'warning', icon: 'exclamation-circle', iconColor: Colors.red },
];

/**
 * @constant {Object} AlertRecipients
 * @memberof Constants
 */
export const AlertRecipients = [
  { value: 'admin_users', label: 'Admin' },
  { value: 'general_users', label: 'General' },
  { value: 'account_admin', label: 'Account Admin' },
  { value: 'location_admin', label: 'Location Admin' },
  { value: 'brand_admin', label: 'Brand Admin' },
  { value: 'customer', label: 'Customer' },
  { value: 'installers', label: 'Installer' },
  {
    value: 'location_point_of_contact',
    label: 'Point of Contact',
  },
];

/**
 * @constant {Object} AlertTrigger
 * @memberof Constants
 */
export const AlertTrigger = {
  '': [],
  'water-filter': [
    {
      label: 'Filter near exhaustion - Filter 80% to <90%',
      value: 'filter-near-exhaustion-80-to-90',
    },
    {
      label: 'Filter near exhaustion - Filter 90% to <95%',
      value: 'filter-near-exhaustion-90-to-95',
    },
    {
      label: 'Filter near exhaustion - Filter 95% to <100%',
      value: 'filter-near-exhaustion-95-to-100',
    },
    { label: 'Filter Exhausted - 100% or more', value: 'filter-exhausted' },
    {
      label: 'Filter Near Expiration - 8 weeks to >4 weeks left',
      value: 'filter-near-expiration-8-to-4-weeks-left',
    },
    {
      label: 'Filter Near Expiration - 4 weeks to >2 weeks left',
      value: 'filter-near-expiration-4-to-2-weeks-left',
    },
    {
      label: 'Filter Near Expiration - 2 weeks to >0 weeks left',
      value: 'filter-near-expiration-2-to-0-weeks-left',
    },
    {
      label: 'Filter Expired - 0 weeks left',
      value: 'filter-expired',
    },
    {
      label: 'Filter Replaced - Successful Filter Replacement',
      value: 'filter-replaced',
    },
    { label: 'Filter Bypass Detected', value: 'filter-bypass-detected' },
    { label: 'Potential Clog', value: 'potential-clog' },
    { label: 'Filter Low Inlet Pressure', value: 'filter-low-inlet-pressure' },
    { label: 'Connection Lost', value: 'connection-lost' },
  ],
  'reverse-osmosis': [
    {
      label: 'RO Filter near exhaustion - Filter 80% to <90%',
      value: 'ro-filter-near-exhaustion-80-to-90',
    },
    {
      label: 'RO Filter near exhaustion - Filter 90% to <95%',
      value: 'ro-filter-near-exhaustion-90-to-95',
    },
    {
      label: 'RO Filter near exhaustion - Filter 95% to <100%',
      value: 'ro-filter-near-exhaustion-95-to-100',
    },
    { label: 'RO Filter Exhausted - 100% or more', value: 'ro-filter-exhausted' },
    {
      label: 'RO Filter Near Expiration - 8 weeks to >4 weeks left',
      value: 'ro-filter-near-expiration-8-to-4-weeks-left',
    },
    {
      label: 'RO Filter Near Expiration - 4 weeks to >2 weeks left',
      value: 'ro-filter-near-expiration-4-to-2-weeks-left',
    },
    {
      label: 'RO Filter Near Expiration - 2 weeks to >0 weeks left',
      value: 'ro-filter-near-expiration-2-to-0-weeks-left',
    },
    { label: 'RO Filter Expired - 0 weeks left', value: 'ro-filter-expired' },
    {
      label: 'RO Filter Replaced - Successful Filter Replacement',
      value: 'ro-filter-replaced',
    },
    { label: 'Filter Bypass Detected', value: 'filter-bypass-detected' },
    { label: 'RO TDS low', value: 'ro-tds-low' },
    { label: 'RO TDS high', value: 'ro-tds-high' },

    { label: 'RO WASTE out', value: 'ro-waste-out' },
    // for this need know errors[] format
    // { label: 'RO low inlet pressure', value: 'ro-low-inlet' },
    // { label: 'RO fallout code', value: 'ro-fallout-code' },
    {
      label: 'RO low tank pressure',
      value: 'ro-low-tank-pressure',
    },
    { label: 'RO Low Inlet Pressure', value: 'ro-low-inlet-pressure' },
    { label: 'Connection Lost', value: 'connection-lost' },
  ],
  'water-softener': [
    { label: 'Low Salt Level', value: 'soft-low-salt-level' },
    { label: 'Half Full Salt Level', value: 'soft-half-full-salt-level' },
    { label: 'No Salt Level', value: 'soft-no-salt-level' },
    { label: 'Softener Filter Bypass Detected', value: 'soft-filter-bypass-detected' },
    { label: 'Softener Low Inlet Pressure', value: 'soft-low-inlet-pressure' },
    { label: 'Connection Lost', value: 'connection-lost' },
  ],
  'reverse-osmosis-membrane': [
    {
      label: 'Membrane Near Exhaustion - Membrane life 15% to 20% remaining',
      value: 'membrane-near-exhaustion-15-to-20-remaining',
    },
    {
      label: 'Membrane Near Exhaustion - Membrane life 5% to 10% remaining',
      value: 'membrane-near-exhaustion-5-to-10-remaining',
    },
    {
      label: 'Membrane Near Exhaustion - Membrane life >0% to 5%',
      value: 'membrane-near-exhaustion-0-to-5',
    },
    {
      label: 'Membrane Exhausted - Membrane life 0%',
      value: 'membrane-exhausted',
    },
  ],
};

export const RepeatAlertSelectOptions = [
  { label: 'No' },
  { label: 'Daily' },
  { label: 'Weekly' },
  { label: 'Two Hourly' },
];

export const RepeatAlertSelectOptionsForByPass = [
  { label: 'Every 40 gallons' },
  { label: 'Every 80 gallons' },
];

/**
 * @constant {Object} AlertFields
 * @memberof Constants
 */
export const AlertFields = [
  'Brand',
  'LocationAddress',
  'LocationContactName',
  'LocationContactNumber',
  'LocationIdentificator',
  'AccountName',
  'DateTime',
  'LocationUrl',
];

/**
 * @constant {Object} PressureTraducerFields
 * @memberof Constants
 */
export const PressureTraducerFields = ['psi_avg'];

/**
 * @constant {Object} TemperatureFields
 * @memberof Constants
 */
export const TemperatureFields = ['temp_avg'];

/**
 * @constant {Object} TdsFields
 * @memberof Constants
 */
export const TdsFields = ['tds_avg'];

/**
 * @constant {Object} AlertFieldsForWFAndRo
 * @memberof Constants
 */
export const AlertFieldsForWFAndRo = [...AlertFields, 'CatridgeModel', 'Position'];

/**
 * @constant {Object} NonInternalRoles
 * @memberof Constants
 */
export const NonInternalRoles = [3, 4, 5];

/**
 * @constant {Object} AdminsRolesId
 * @memberof Constants
 */
export const AdminsRolesId = [7, 8, 9];

/**
 * @constant {Object} CustomerRoleId
 * @memberof Constants
 */
export const CustomerRoleId = 3;

/**
 * @constant {Object} AccountAdminRoleId
 * @memberof Constants
 */
export const AccountAdminRoleId = 8;

/**
 * @constant {Object} LocationAdminRoleId
 * @memberof Constants
 */
export const LocationAdminRoleId = 9;

/**
 * @constant {Object} KineticoRolesCode
 * @memberof Constants
 */
export const KineticoRolesCode = ['admin', 'general'];

/**
 * @constant {Object} BrandsRolesCode
 * @memberof Constants
 */
export const BrandsRolesCode = [
  'customer',
  'external',
  'installer',
  'brand_admin',
  'account_admin',
  'location_admin',
];

/**
 * @constant {Object} SeriesOrParallel
 * @memberof Constants
 */
export const SeriesOrParallel = [
  { label: 'N/A', value: 'N/A' },
  { label: 'Series', value: 'Series' },
  { label: 'Parallel', value: 'Parallel' },
];

/**
 * @constant {Object} PositionLabels
 * @memberof Constants
 */
export const PositionLabels = [
  {
    label: '1 - Center',
    value: '1 - Center',
  },
  {
    label: '1 - Left',
    value: '1 - Left',
  },

  {
    label: '1 - Right',
    value: '1 - Right',
  },
  {
    label: '2 - Center',
    value: '2 - Center',
  },
  {
    label: '2 - Left',
    value: '2 - Left',
  },

  {
    label: '2 - Right',
    value: '2 - Right',
  },
];

/**
 * @constant {Object} PositionLabelsRO
 * @memberof Constants
 */
export const PositionLabelsRO = [
  ...PositionLabels,
  {
    label: 'Pre-sediment filter',
    value: 'Pre-sediment filter',
  },
  {
    label: 'Pre-carbon filter',
    value: 'Pre-carbon filter',
  },
  {
    label: 'Carbon filter',
    value: 'Carbon filter',
  },
];

/**
 * @constant {Object} Outlets
 * @memberof Constants
 */
export const Outlets = [
  {
    label: 'Outlet 1',
    value: 'Outlet 1',
  },
  {
    label: 'Outlet 2',
    value: 'Outlet 2',
  },
  {
    label: 'Outlet 3',
    value: 'Outlet 3',
  },
];

/**
 * @constant {Object} Warranties
 * @memberof Constants
 */
export const Warranties = [
  {
    label: '1 year (52 weeks)',
    value: 1,
  },
  {
    label: '2 years (104 weeks)',
    value: 2,
  },
  {
    label: '3 years (156 weeks)',
    value: 3,
  },
  {
    label: '4 years (208 weeks)',
    value: 4,
  },
  {
    label: '5 years (260 weeks)',
    value: 5,
  },
];

/**
 * @constant {Object} ValuesFilter
 * @memberof Constants
 */
export const ValuesFilter = [
  {
    label: 'Value 1',
    value: 'Value 1',
  },
  {
    label: 'Value 2',
    value: 'Value 2',
  },
  {
    label: 'Value 3',
    value: 'Value 3',
  },
  {
    label: 'Value 4',
    value: 'Value 4',
  },
  {
    label: 'Value 5',
    value: 'Value 5',
  },
];

/**
 * @constant {Object} UPGRADE_OPTIONS_LOCATION
 * @memberof Constants
 */
export const UPGRADE_OPTIONS_LOCATION = [
  {
    device: 'Filter/Softener',
    current_version: 'V 1.0',
    upgrade_to: 'V 1.2',
  },
  {
    device: 'Reverse Osmosis',
    current_version: 'V 1.0',
    upgrade_to: 'V 1.2',
  },
];

/**
 * @constant {Object} MATCH_DEVICE_TYPE
 * @memberof Constants
 */
export const MATCH_DEVICE_TYPE = {
  'water-filter': 'Water Filter',
  'water-softener': 'Softener',
  'reverse-osmosis': 'Reverse Osmosis',
};

/**
 * @constant {Object} UPGRADE_OPTIONS_DEVICE_FAMILY
 * @memberof Constants
 */
export const UPGRADE_OPTIONS_DEVICE_FAMILY = [
  {
    location: 'Zachs Home Soften...',
    account: 'Zachary',
    current_version: 'V 1.0',
  },
  {
    location: 'Zachs Home Soften...',
    account: 'Zachary',
    current_version: 'V 1.0',
  },
];

/**
 * @constant {Object} UPGRADE_OPTIONS_BRAND
 * @memberof Constants
 */
export const UPGRADE_OPTIONS_BRAND = [
  {
    location: 'Zachs Home Soften...',
    account: 'Zachary',
    device_type: 'Reverse Osmosis',
    current_version: 'V 1.0',
  },
  {
    location: 'Zachs Home Soften...',
    account: 'Zachary',
    device_type: 'Reverse Osmosis',
    current_version: 'V 1.0',
  },
];

/**
 * @constant {Object} LIST_ACTIVE_DEVICES
 * @memberof Constants
 */
export const LIST_ACTIVE_DEVICES = [
  {
    full_address: 'kyles way 2, Amador City, CA 72623',
    brand_name: 'Testing Brand',
    account: 'Zachary',
    device: [
      {
        device_type: 'Filter/ Softener',
        device_name: 'Test Softener Model',
        version: '1.3',
        last_update: '12/ 10 / 2021',
        status: 'Queued',
      },
    ],
  },
  {
    full_address: 'kyles way 2, Amador City, CA 72623',
    brand_name: 'Testing Brand',
    account: 'Zachary',
    device: [
      {
        device_type: 'Filter/ Softener',
        device_name: 'Test Softener Model',
        version: '1.3',
        last_update: '12/ 10 / 2021',
        status: 'Cancelled',
      },
    ],
  },
  {
    full_address: 'kyles way 2, Amador City, CA 72623',
    brand_name: 'Testing Brand',
    account: 'Zachary',
    device: [
      {
        device_type: 'Filter/ Softener',
        device_name: 'Test Softener Model',
        version: '1.3',
        last_update: '12/ 10 / 2021',
        status: 'Update Failed',
      },
    ],
  },
];

/**
 * @constant {Object} StatusTypes
 * @memberof Constants
 */
export const StatusTypes = [
  { label: 'Queued', value: 'QUEUED' },
  { label: 'In Progress', value: 'IN_PROGRESS' },
  { label: 'Succeded', value: 'SUCCEEDED' },
  { label: 'Failed', value: 'FAILED' },
  { label: 'Timed Out', value: 'TIMED_OUT' },
  { label: 'Rejected', value: 'REJECTED' },
  { label: 'Removed', value: 'REMOVED' },
  { label: 'Canceled', value: 'CANCELED' },
];

/**
 * @constant {Object} StatusTypesCreationJob
 * @memberof Constants
 */
export const StatusTypesCreationJob = [
  { label: 'Create Complete', value: 'CREATE_COMPLETE' },
  { label: 'Create Failed', value: 'CREATE_FAILED' },
  { label: 'Create In Progress', value: 'CREATE_IN_PROGRESS' },
  { label: 'Create Pending', value: 'CREATE_PENDING' },
  { label: 'Queued', value: 'QUEUED' },
];

/**
 * @constant {Object} PASS_REG_EXP
 * @memberof Constants
 */
export const PASS_REG_EXP = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?#&])[A-Za-z\d@$!%*?#&]{8,}$/;
