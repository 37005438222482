import React, { Component } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { Colors } from 'modules/theme';
import { push } from 'modules/history';
import ButtonText from 'components/ButtonText';
import { InstallationStatus } from 'constants/index';
import H3 from 'components/H3';
import H4 from 'components/H4';
import H5 from 'components/H5';
import H2 from 'components/H2';
import Col from 'components/Col';
import Row from 'components/Row';
import Line from 'components/Line';
import Filter, { FilterGroup, FilterContainer } from 'components/Filter';
import Icon from 'components/Icon';
import BG from 'components/BG';
import ListEmpty from 'components/ListEmpty';
import Modal from 'components/Modal';
import PaginationButtons from 'components/PaginationButtons';
import PropTypes from 'prop-types';
import WF from './WF';
import WS from './WS';
import RO from './RO';

const Container = styled.div`
  margin: 30px;
`;

const InstallationNotCompleteContainer = styled.div`
  margin-top: 100px;
  margin-bottom: 100px;
`;

const membraneLife = [
  { name: 'nocomplete', value: 50, fill: Colors.grayChart },
  { name: 'complete', value: 50, fill: Colors.blue },
];
export default class WaterFilterForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterHeadSelected: '',
      showContactInfo: false,
      alertsModal: false,
    };
  }

  switchByFilterHead = filterHead => {
    const { location, allAlerts, fetchAllAlerts, setDeviceId } = this.props;

    switch (filterHead) {
      case 'WaterFilterHead':
        return (
          <WF
            location={location}
            allAlerts={allAlerts}
            fetchAllAlerts={fetchAllAlerts}
            renderInstructionsModal={this.renderInstructionsModal}
            setDeviceId={setDeviceId}
          />
        );
      case 'ReverseOsmosisFilterHead':
        return (
          <RO
            location={location}
            membraneLife={membraneLife}
            renderInstructionsModal={this.renderInstructionsModal}
            allAlerts={allAlerts}
            fetchAllAlerts={fetchAllAlerts}
            setDeviceId={setDeviceId}
          />
        );
      case 'SoftenerFilterHead':
        return (
          <WS
            location={location}
            renderInstructionsModal={this.renderInstructionsModal}
            allAlerts={allAlerts}
            fetchAllAlerts={fetchAllAlerts}
            setDeviceId={setDeviceId}
          />
        );
      default:
        return (
          <InstallationNotCompleteContainer>
            <ListEmpty message="There are no devices configured" />
          </InstallationNotCompleteContainer>
        );
    }
  };

  renderContactInfoModal = () => {
    const { showContactInfo } = this.state;
    const { location } = this.props;
    const {
      brand_name,
      account_name,
      identificator,
      contact_first_name,
      contact_last_name,
      contact_email,
      contact_phone_number,
    } = location;

    return (
      <Modal
        title="Contact info"
        onClose={() => this.switchModal('showContactInfo')}
        isVisible={showContactInfo}
        size="small"
      >
        <H5 color={Colors.gray8} weight={325} style={{ margin: 0 }}>
          Brand name
        </H5>
        <H4 textAlign="center" color={Colors.black} weight={500} style={{ marginTop: 5 }}>
          {brand_name}
        </H4>
        <H5 color={Colors.gray8} weight={325} style={{ margin: 0 }}>
          Account name
        </H5>
        <H4 textAlign="center" color={Colors.black} weight={500} style={{ marginTop: 5 }}>
          {account_name}
        </H4>
        <H5 color={Colors.gray8} weight={325} style={{ margin: 0 }}>
          Location Id
        </H5>
        <H4 textAlign="center" color={Colors.black} weight={500} style={{ marginTop: 5 }}>
          {identificator}
        </H4>
        <H5 color={Colors.gray8} weight={325} style={{ margin: 0 }}>
          Contact Name
        </H5>
        <H4 textAlign="center" color={Colors.black} weight={500} style={{ marginTop: 5 }}>
          {`${contact_first_name} ${contact_last_name}`}
        </H4>
        <H5 color={Colors.gray8} weight={325} style={{ margin: 0 }}>
          Email
        </H5>
        <H4 textAlign="center" color={Colors.black} weight={500} style={{ marginTop: 5 }}>
          {contact_email}
        </H4>
        <H5 color={Colors.gray8} weight={325} style={{ margin: 0 }}>
          Phone
        </H5>
        <H4 textAlign="center" color={Colors.black} weight={500} style={{ marginTop: 5 }}>
          {contact_phone_number}
        </H4>
      </Modal>
    );
  };

  renderInstructionsModal = (isVisible, onClose, installSteps) => (
    <Modal title="Installation Steps" onClose={onClose} isVisible={isVisible}>
      {installSteps.map(step => (
        <>
          <Row justifyContent="flex-start" alignItems="start" style={{ padding: 10 }}>
            <Col
              xs={step.photo ? 50 : 100}
              md={step.photo ? 50 : 100}
              sm={step.photo ? 50 : 100}
              lg={step.photo ? 50 : 100}
              alignItems="left"
            >
              <H2 color={Colors.gray}>
                Step {step.index + 1} -
                <H2 color={Colors.black} style={{ marginLeft: 5 }}>
                  {step.title}
                </H2>
              </H2>

              <H3 color={Colors.black}>{step.description}</H3>
            </Col>
            {step.photo && (
              <Col xs={50} md={50} sm={50} lg={50}>
                <img width="250" src={step.photo} />
              </Col>
            )}
          </Row>
          <Line />
        </>
      ))}
    </Modal>
  );

  renderAlertsModal = () => {
    const { alertsModal } = this.state;
    const {
      fetchAlerts,
      alerts,
      alertsPages,
      alertsCurrentPage,
      location: { id: location_id },
    } = this.props;

    return (
      <Modal
        title="Alerts"
        onClose={() => this.switchModal('alertsModal')}
        isVisible={alertsModal}
        size="large"
      >
        {alerts && alerts.length > 0 ? (
          <Row>
            {alerts.map(alert => {
              let iconColor;
              switch (alert.type) {
                case 'alert':
                  iconColor = Colors.orange;
                  break;
                case 'warning':
                  iconColor = Colors.red;
                  break;
                default:
                  iconColor = Colors.green;
                  break;
              }
              const iconName = alert.type === 'success' ? 'check-circle' : 'exclamation-circle';

              return (
                <Row>
                  <Col lg={30} sm={30} md={30} alignItems="flex-start">
                    <Row justifyContent="end">
                      <Icon
                        fontSize={30}
                        name={iconName}
                        color={iconColor}
                        customStyle={{ marginRight: 10 }}
                      />
                      <H3 weight="bold">{alert.title}</H3>
                    </Row>
                  </Col>

                  <Col lg={50} sm={50} md={50} alignItems="flex-end">
                    <H3 color={Colors.gray} weight="lighter">
                      {moment(alert.created_at).format('MM/DD/YYYY hh:mm a')}
                    </H3>
                  </Col>

                  <Line />
                </Row>
              );
            })}

            <Row justifyContent="end" style={{ marginTop: 20 }}>
              <PaginationButtons
                currentPage={alertsCurrentPage}
                changePage={page =>
                  fetchAlerts({
                    page,
                    location_id,
                  })
                }
                totalPages={alertsPages}
              />
            </Row>
          </Row>
        ) : (
          <H3 color={Colors.gray}>There are no alerts.</H3>
        )}
      </Modal>
    );
  };

  switchModal = name => {
    const isVisible = this.state[name];

    this.setState({ [name]: !isVisible });
  };

  handleChange = evt => {
    const { name, value } = evt.target;

    this.setState({ [name]: value });
  };

  handleChangeInstallationStatus = evt => {
    const {
      updateInstallationStatus,
      location: { id },
    } = this.props;
    const installed = evt.target.value === 'true';
    updateInstallationStatus(id, { installed });
  };

  render() {
    const { filterHeadSelected } = this.state;
    const { location, alerts, role_id } = this.props;
    const {
      identificator,
      brand_name,
      full_address,
      WaterFilterHead,
      ReverseOsmosisFilterHead,
      SoftenerFilterHead,
    } = location;

    const filterHeads = [];

    if (WaterFilterHead) {
      filterHeads.push({
        label: `${WaterFilterHead.WaterFilterConfiguration.name} (${WaterFilterHead.chlorine_or_chloramine})`,
        value: 'WaterFilterHead',
      });
    }
    if (ReverseOsmosisFilterHead) {
      filterHeads.push({
        label: `${ReverseOsmosisFilterHead.ReverseOsmosisFilterConfiguration.name}`,
        value: 'ReverseOsmosisFilterHead',
      });
    }
    if (SoftenerFilterHead) {
      filterHeads.push({
        label: `${SoftenerFilterHead.SoftenerConfiguration.name} (${SoftenerFilterHead.chlorine_or_chloramine})`,
        value: 'SoftenerFilterHead',
      });
    }

    const selected = filterHeadSelected || (filterHeads.length ? filterHeads[0].value : '');
    return (
      <Container style={{ paddingTop: 10, paddingBottom: 10 }}>
        <H4 color={Colors.blue}>
          <Icon
            customStyle={{ marginRight: '10px' }}
            onClick={() => push('/locations')}
            name="chevron-left"
            color={Colors.blue}
          />
          Brand - Location Id
        </H4>
        <H4 color={Colors.black} style={{ margin: 0 }}>
          {`${brand_name}, #${identificator}`}
        </H4>

        <Row>
          <Col lg={50} md={50} sm={100} xs={100} alignItems="flex-start">
            <FilterGroup style={{ paddingTop: 0, paddingBottom: 0 }}>
              <FilterContainer style={{ marginLeft: -10, padding: 0, justifyContent: 'center' }}>
                {selected && location.installed && (
                  <Filter
                    selectedValue={selected}
                    name="filterHeadSelected"
                    handleChange={this.handleChange}
                    options={filterHeads}
                  />
                )}

                {!selected && location.installed && (
                  <H4 color={Colors.gray} weight={600}>
                    There are no devices configured
                  </H4>
                )}

                {!location.installed && (
                  <H4 color={Colors.gray} weight={600}>
                    Installation Not Complete
                  </H4>
                )}
              </FilterContainer>
            </FilterGroup>
            {role_id === 1 && (
              <FilterGroup style={{ paddingTop: 0, paddingBottom: 0 }}>
                <FilterContainer style={{ marginLeft: -10, padding: 0, justifyContent: 'center' }}>
                  <Row justifyContent="end">
                    <H4 color={Colors.black}>Installation status</H4>
                    <Filter
                      selectedValue={location.installed}
                      name="installationStatus"
                      handleChange={this.handleChangeInstallationStatus}
                      options={InstallationStatus}
                    />
                  </Row>
                </FilterContainer>
              </FilterGroup>
            )}

            <H3 color={Colors.black} style={{ marginTop: 0, marginBottom: 10 }}>
              <Icon
                name="fas fa-home"
                color={Colors.lightBlue}
                customStyle={{ marginRight: '10px' }}
              />
              {full_address}
            </H3>
          </Col>

          <Col lg={50} md={50} sm={100} xs={100} alignItems="flex-end">
            <ButtonText
              text="Contact info"
              theme="round"
              onClick={() => this.switchModal('showContactInfo')}
            />
          </Col>
        </Row>

        {location.installed ? (
          this.switchByFilterHead(selected)
        ) : (
          <InstallationNotCompleteContainer>
            <ListEmpty message="Installation Not Complete" />
          </InstallationNotCompleteContainer>
        )}
        {location.installed && (
          <BG width="100%">
            <Row>
              <Col lg={50} sm={50} md={50} xs={50} alignItems="flex-start">
                <H3>Alerts</H3>
              </Col>
              <Col lg={50} sm={50} md={50} xs={50} alignItems="flex-end">
                <H3
                  onClick={() => this.switchModal('alertsModal')}
                  weight="bold"
                  color={Colors.lightBlue}
                  style={{ cursor: 'pointer' }}
                >
                  Open More
                </H3>
              </Col>
              {alerts && alerts.length > 0 ? (
                alerts.slice(0, 2).map(alert => {
                  let iconColor;
                  switch (alert.type) {
                    case 'alert':
                      iconColor = Colors.orange;
                      break;
                    case 'warning':
                      iconColor = Colors.red;
                      break;
                    default:
                      iconColor = Colors.green;
                      break;
                  }

                  const iconName = alert.type === 'success' ? 'check-circle' : 'exclamation-circle';

                  return (
                    <Row>
                      <Col lg={70} sm={70} md={70} xs={70} alignItems="flex-start">
                        <Row justifyContent="start">
                          <Icon
                            fontSize={30}
                            name={iconName}
                            color={iconColor}
                            customStyle={{ marginRight: 10 }}
                          />
                          <H3 weight="bold">{alert.title}</H3>
                        </Row>
                      </Col>

                      <Col lg={30} sm={30} md={30} alignItems="flex-end">
                        <H3 color={Colors.gray} weight="lighter">
                          {moment(alert.created_at).format('MM/DD/YYYY hh:mm a')}
                        </H3>
                      </Col>

                      <Line />
                    </Row>
                  );
                })
              ) : (
                <H3 color={Colors.gray}>There are no alerts.</H3>
              )}
            </Row>
          </BG>
        )}

        {this.renderContactInfoModal()}
        {this.renderAlertsModal()}
      </Container>
    );
  }
}

WaterFilterForm.propTypes = {
  alerts: PropTypes.array,
  alertsCurrentPage: PropTypes.number,
  alertsPages: PropTypes.number,
  allAlerts: PropTypes.array,
  fetchAlerts: PropTypes.func,
  fetchAllAlerts: PropTypes.func,
  location: PropTypes.object,
  markAlertAsViewed: PropTypes.func,
  role_id: PropTypes.number,
  setDeviceId: PropTypes.func,
  updateInstallationStatus: PropTypes.func,
};
